import { makeVar } from "@apollo/client"

export interface CartContext {
  uid?: string
  cart: Record<string, number>
}

let initialState: CartContext = {
  cart: {},
}

const oldState = window.localStorage.getItem("cc_crt")
if (oldState) {
  initialState = JSON.parse(oldState)
}

const cartVar = makeVar(initialState)

export default cartVar
