export const getSizeString = (string: string | null | undefined) => {
  if (string) {
    const normalSizes = ["small", "medium", "large", "x-large"]
    if (normalSizes.includes(string.toLowerCase())) {
      return string
    } else {
      return "One size"
    }
  }
}
