import { ProductCategoryVarType } from "../../apollo/vars/productCategoryVar"
import { ProductCatalogueQuery } from "../../generated/graphql"

const processCategoryData = (data: ProductCatalogueQuery) => {
  const sortingArray = [
    "Protectors",
    "Liners",
    "Pull-Up Pants",
    "All-In-One",
    "Other",
  ]

  const categories: ProductCategoryVarType = {
    male: [],
    female: [],
  }

  data.categories?.forEach((category) => {
    if (category?.__typename === "productType_Category") {
      if (category.gender) {
        category.gender.forEach((gender) => {
          gender?.title === "Women"
            ? categories.female.push(category)
            : categories.male.push(category)
        })
      }
    }
  })

  Object.keys(categories).forEach((key) => {
    categories[key as keyof ProductCategoryVarType].sort((a, b) => {
      if (
        a.__typename === "productType_Category" &&
        b.__typename === "productType_Category" &&
        a.categoryShortTitle &&
        b.categoryShortTitle
      ) {
        return (
          sortingArray.indexOf(a.categoryShortTitle) -
          sortingArray.indexOf(b.categoryShortTitle)
        )
      }
      return -1
    })
  })

  return categories
}

export default processCategoryData
