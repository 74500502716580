import Cormie_logo_header from "../assets/images/Cormie_logo_header.svg"
import { Field, Formik, FormikHelpers, Form } from "formik"
import React, { useEffect, useState } from "react"
import { LoginMutationVariables, useLoginMutation } from "../generated/graphql"
import login from "../utils/user/login"
import * as Yup from "yup"
import { useNavigate } from "react-router-dom"
import { useReactiveVar } from "@apollo/client"
import isLoggedInVar from "../apollo/vars/isLoggedInVar"
import { Col, Container, Image, Row } from "react-bootstrap"
import MainButton from "../components/MainButton"

const Login: React.FC = () => {
  const navigate = useNavigate()
  const isLoggedIn = useReactiveVar(isLoggedInVar)

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/")
    }
  })

  const validationSchema = Yup.object().shape({
    loginName: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string().required("Required"),
  })

  const [loginMutation] = useLoginMutation()
  const [errorMessage, setErrorMessage] = useState("")

  const onSubmit = async (
    values: LoginMutationVariables,
    { setSubmitting }: FormikHelpers<LoginMutationVariables>,
  ) => {
    await loginMutation({
      variables: {
        loginName: values.loginName,
        password: values.password,
        remember: values.remember,
      },
      onCompleted: (data) => {
        if (data?.login?.token) {
          login(data.login.token)
        } else {
          setErrorMessage("Unable to log in user, network error")
        }
        setSubmitting(false)
      },
      onError: (error) => {
        if (error.message === "Invalid username or password") {
          setErrorMessage(
            "Unable to log in, please check your username and password",
          )
        } else {
          setErrorMessage(
            "An unexpected error occured, we'll be back online soon!",
          )
        }
        setSubmitting(false)
      },
    })
  }

  return (
    <>
      <Container className="vh-100 p-0 p-sm-auto">
        <Row
          style={{
            maxWidth: "100vw",
          }}
          className="justify-content-center align-items-center h-100"
        >
          <Col className="login-col col-12 col-md-8 col-lg-5 p-5">
            <Row>
              <Image
                src={Cormie_logo_header}
                fluid
                className="pb-4 mb-5 px-0 px-sm-5 px-lg-4 px-xl-5 border-bottom"
                alt="Cormie Logo"
              />
            </Row>
            <Row>
              <h1 className="d-none">Login page</h1>
              <h3 className="p-0 mb-4 fw-bold">Login</h3>
            </Row>
            {errorMessage !== "" && (
              <Row>
                <h2 className="alert alert-danger login-alert">
                  {errorMessage}
                </h2>
              </Row>
            )}
            <Formik
              initialValues={{
                loginName: "",
                password: "",
                remember: false,
              }}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
              validateOnMount
            >
              {({ isSubmitting, isValid }) => (
                <Form>
                  <div className="form-group mt-1 mb-4">
                    <label htmlFor="loginName">Email Address *</label>
                    <Field
                      id="loginName"
                      name="loginName"
                      placeholder="you@example.com"
                      className="form-control"
                    />
                  </div>

                  <div className="form-group mb-4">
                    <label htmlFor="password">Password *</label>
                    <Field
                      id="password"
                      name="password"
                      type="password"
                      className="form-control"
                    />
                  </div>

                  <div className="d-flex justify-content-center">
                    <MainButton
                      type="submit"
                      disabled={!isValid || isSubmitting}
                      color="red-gradient"
                      className="login-submit"
                    >
                      SIGN IN
                    </MainButton>
                  </div>
                </Form>
              )}
            </Formik>
            <Row>
              <a
                href={`${process.env.REACT_APP_RESET_PASSWORD_URL}`}
                className="password-reset-text text-center"
              >
                Forgot your password?
              </a>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Login
