import React from "react"
import { Row, Col } from "react-bootstrap"
import CormieProfileEditButton from "../components/CormieProfileEditButton"

const CormieSectionTitle: React.FC<{
  children: React.ReactNode
  editable?: boolean
  editCallback?: () => void
  horizontalRule?: boolean
  className?: string
}> = ({
  children,
  editable = false,
  horizontalRule = true,
  editCallback,
  className,
}) => {
  return (
    <>
      <Row className={className ? className : "" + " mb-2"}>
        <Col className="d-flex align-items-center">
          <h6 className="m-0">{children}</h6>
        </Col>
        {editable && <CormieProfileEditButton editCallback={editCallback} />}
      </Row>
      {horizontalRule && <hr></hr>}
    </>
  )
}

export default CormieSectionTitle
