import { makeVar } from "@apollo/client"
import { ProductDetailsFragment } from "../../generated/graphql"

export type ProductCatalogueVarType = {
  [key: string]: ProductDetailsFragment
}

const initialState: ProductCatalogueVarType = {}

const productCatalogueVar = makeVar(initialState)

export default productCatalogueVar
