const removeTrailingBackslashes = (str: string): string => {
  str = str.slice(0, -1)
  if (str.charAt(str.length - 1) === "\\") {
    return removeTrailingBackslashes(str)
  } else {
    return str
  }
}

export default removeTrailingBackslashes
