import { faPlus, faX } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { Badge, Col, Dropdown, Form, Row } from "react-bootstrap"
import Toggle from "react-toggle"
import CormiePillBadge from "./CormiePillBadge"

const SearchTags: React.FC<{
  tags: Record<string, boolean>
  setTags: (value: React.SetStateAction<Record<string, boolean>>) => void
  ignoreTags?: boolean
  setIgnoreTags?: (value: React.SetStateAction<boolean>) => void
  toggleAllPrompt?: string
}> = ({ tags, setTags, ignoreTags, setIgnoreTags, toggleAllPrompt }) => {
  const [tagFilter, setTagFilter] = useState("")

  const handleTagClick = (tag: string) => {
    if (!ignoreTags) {
      tags[tag] = !tags[tag]
      setTags({ ...tags })
    } else {
      tags[tag] = true // If the tag set is not focused, the user probably wants to focus it rather than remove the tag
    }

    if (setIgnoreTags) {
      setIgnoreTags(false)
    }

    let allTagsFalse = true
    Object.keys(tags).forEach((k) => {
      if (tags[k]) {
        allTagsFalse = false
      }
    })

    if (allTagsFalse && setIgnoreTags) {
      setIgnoreTags(true)
    }
  }

  const selectedTags = Object.keys(tags).map((tag, i) => {
    if (tag && tags[tag]) {
      return (
        <div className="mb-2 me-1" key={i}>
          <CormiePillBadge
            key={i}
            blue
            onClick={() => handleTagClick(tag)}
            className={
              (ignoreTags || !tags[tag] ? "tag-ignore  " : "") +
              (!ignoreTags ? "hover " : "") +
              "non-table-tags fs-6 fw-normal"
            }
          >
            <u>{tag}</u>{" "}
            <FontAwesomeIcon
              icon={faX}
              className={"cormie-badge-icon mx-1"}
              data-testid={`tag${i}`}
            />
          </CormiePillBadge>
        </div>
      )
    }
  })

  const dropdownTags = Object.keys(tags).map((tag, i) => {
    if (tag) {
      let display = true
      if (tagFilter != "") {
        const regex = new RegExp(tagFilter.toLowerCase())
        display = regex.test(tag.toLowerCase())
      }
      if (display) {
        return (
          <Dropdown.Item key={i}>
            <CormiePillBadge
              key={i}
              blue
              onClick={() => handleTagClick(tag)}
              className={
                (ignoreTags || !tags[tag]
                  ? "tag-ignore py-3 px-4 ms-1 fs-6 fw-normal"
                  : "py-3 px-4 ms-1 fs-6 fw-normal") +
                (!ignoreTags
                  ? "hover py-3 px-4 ms-1 fs-6 fw-normal"
                  : "py-3 px-4 ms-1 fs-6 fw-normal")
              }
            >
              <u>{tag}</u>{" "}
              <FontAwesomeIcon
                icon={faX}
                className={"cormie-badge-icon mx-1"}
                data-testid={`tag${i}`}
              />
            </CormiePillBadge>
          </Dropdown.Item>
        )
      }
    }
  })

  const handleChange = (e: React.ChangeEvent<any>) => {
    setTagFilter(e.target.value)
  }

  const CustomToggle: React.ForwardRefExoticComponent<{
    children: React.ReactNode
    onClick: (e: any) => void
    // eslint-disable-next-line
  }> = React.forwardRef(({ children, onClick }, ref: any) => (
    <a
      ref={ref}
      onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}
    >
      {children}
    </a>
  ))

  return (
    <Row className="my-3 px-sm-3 flex-column flex-md-row" xs={1}>
      <Col className="d-flex flex-column">
        <Col className="d-flex flex-wrap">
          {selectedTags && <>{selectedTags}</>}
        </Col>
        <Col>
          {dropdownTags && (
            <Dropdown className="d-inline ps-1 mt-3">
              <Dropdown.Toggle as={CustomToggle}>
                <Badge
                  className={
                    (!ignoreTags ? "hover " : "") + "tag-dropdown-plus"
                  }
                >
                  <FontAwesomeIcon icon={faPlus} />
                  <span className="tag-add-button-label">
                    {ignoreTags ? "Filter by tag" : "Add tag"}
                  </span>
                </Badge>
              </Dropdown.Toggle>
              <Dropdown.Menu className="limited-dropdown">
                <div className="px-4">
                  <Form.Control placeholder="Search" onChange={handleChange} />
                </div>
                {dropdownTags}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </Col>
      </Col>
      {setIgnoreTags && toggleAllPrompt && (
        <Col className="d-flex align-items-center col-auto mb-3 mb-md-0 mt-3 w-100 justify-content-end">
          <span className="me-3">{toggleAllPrompt}</span>
          <Toggle
            checked={ignoreTags}
            aria-label="Show all toggle"
            readOnly
            onClick={() => {
              if (setIgnoreTags) {
                setIgnoreTags(!ignoreTags)
              }
            }}
          />
        </Col>
      )}
    </Row>
  )
}

export default SearchTags
