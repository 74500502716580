import {
  faCheck,
  faMinus,
  faPlus,
  faX,
} from "@fortawesome/free-solid-svg-icons"
import { faCirclePlus } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { Col, Image, Row } from "react-bootstrap"
import cartVar from "../apollo/vars/cartVar"
import productCatalogueVar from "../apollo/vars/productCatalogueVar"
import placeholderImage from "../assets/images/cormie_placeholder_image.jpg"
import {
  EditProfileMutationVariables,
  UserDetailsFragment,
} from "../generated/graphql"
import addToCart from "../utils/cart/addToCart"
import resetCart from "../utils/cart/resetCart"
import setCart from "../utils/cart/setCart"
import { getSizeString } from "../utils/product/getSizeString"
import getGender from "../utils/user/getGender"
import MainButton from "./MainButton"
import ProductSelectorModal from "./ProductSelectorModal"
import { LocalPermacartType } from "./UserPermacart"

const UserPermacartItems: React.FC<{
  localPermacart: LocalPermacartType
  setLocalPermacart: React.Dispatch<React.SetStateAction<LocalPermacartType>>
  permacartLoading: boolean
  isEdit?: boolean
  user: UserDetailsFragment | null | undefined
  cartBelongsToUser: boolean
  cartHasItems: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean | undefined>>
  setModalCallback: React.Dispatch<React.SetStateAction<(() => void) | undefined>>
  userProfileState?: EditProfileMutationVariables & {
    routeTags: (string | null)[]
  }
}> = ({
  localPermacart,
  setLocalPermacart,
  permacartLoading,
  isEdit,
  user,
  cartBelongsToUser,
  cartHasItems,
  setShowModal,
  setModalCallback,
  userProfileState,
}) => {
  const catalogue = productCatalogueVar()
  const mappedLineItems: (JSX.Element | undefined)[] = []
  const cart = cartVar()
  const [showProductModal, setShowProductModal] = useState<boolean>()

  if (localPermacart && localPermacart.length > 0 && !permacartLoading) {
    localPermacart.forEach((lineItem, lineItemIndex) => {
      const product = catalogue[lineItem.id]
      if (
        product &&
        (product.__typename === "incontinenceProducts_Product" ||
          product.__typename === "inserts_Product" ||
          product.__typename === "otherCarersCenterProductType_Product") &&
        product.genders &&
        product.genders[0] &&
        product.variant?.id &&
        product.title
      ) {
        const imageUrl =
          product.listingGallery && product.listingGallery[0]?.url
            ? product.listingGallery[0].url
            : placeholderImage
        const alreadyInCart = Object.keys(cart.cart).includes(
          product.variant.id,
        )

        mappedLineItems.push(
          <Col key={lineItemIndex} className="product-item-col py-3 px-2 mb-2">
            <Row className="flex-row">
              <Col className="d-flex align-items-center me-1 col-12 col-sm-5 product-img">
                <Image
                  style={{ objectFit: "contain" }}
                  src={imageUrl}
                  className="global-max-height-220"
                  fluid
                />
              </Col>
              <Col className="ms-1 d-flex flex-column permacart-item-product-details">
                <Row className="mb-xl-1">
                  <Col>
                    <b className="p-0">{product?.title}</b>
                  </Col>
                  {isEdit && (
                    <Col className="d-flex justify-content-end">
                      <FontAwesomeIcon
                        icon={faX}
                        role="button"
                        data-testid={`permacartProductRemove${lineItemIndex}`}
                        className={"mx-3"}
                        onClick={() => {
                          const newPermacart: { id: string; qty: number }[] = []
                          localPermacart.forEach((obj) => {
                            if (obj.id != product?.variant?.id) {
                              newPermacart.push(obj)
                            }
                          })
                          setLocalPermacart(newPermacart)
                        }}
                      />
                    </Col>
                  )}
                </Row>
                {product?.volume ? (
                  <Row>
                    <Col className="detail-text muted-text">{"Absorbency"}</Col>
                    <Col>{product?.volume + "ml"}</Col>
                  </Row>
                ) : (
                  <></>
                )}
                <Row className="mb-2">
                  <Col className="detail-text muted-text">{"Size"}</Col>
                  <Col>{getSizeString(product.variant?.title)}</Col>
                </Row>
                <Row>
                  {isEdit ? (
                    <>
                      <Col>
                        <span
                          className="quantity minus"
                          data-testid={`minus${lineItemIndex}`}
                          onClick={() => {
                            if (lineItem.qty > 1) {
                              const newPermacart = localPermacart.map((obj) => {
                                if (obj.id === product.variant?.id) {
                                  obj.qty -= 1
                                }
                                return obj
                              })
                              setLocalPermacart(newPermacart)
                            }
                          }}
                        >
                          <FontAwesomeIcon icon={faMinus} />
                        </span>
                        <span className="quantity number">{lineItem.qty}</span>
                        <span
                          className="quantity plus"
                          data-testid={`plus${lineItemIndex}`}
                          onClick={() => {
                            const newPermacart = localPermacart.map((obj) => {
                              if (obj.id === product.variant?.id) {
                                obj.qty += 1
                              }
                              return obj
                            })
                            setLocalPermacart(newPermacart)
                          }}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </span>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col className="detail-text muted-text">{"Qty"}</Col>
                      <Col>{lineItem.qty}</Col>
                    </>
                  )}
                </Row>
                {!isEdit && (
                  <Row className="mt-auto mt-xl-2">
                    <MainButton
                      className="m-0 mt-1 add-to-one-off-order-button"
                      invert={!cartBelongsToUser || !alreadyInCart}
                      disabled={alreadyInCart && cartBelongsToUser}
                      pressed={alreadyInCart && cartBelongsToUser}
                      onClick={() => {
                        // typechecks
                        if (product?.variant?.id && user?.id) {
                          if (cartBelongsToUser) {
                            if (
                              !Object.keys(cart.cart).includes(
                                product.variant.id,
                              )
                            ) {
                              addToCart(product.variant.id, lineItem.qty)
                            }
                          } else {
                            if (cartHasItems) {
                              setModalCallback(() => () => {
                                if (product.variant?.id) addToCart(product.variant.id, lineItem.qty)
                              })
                              setShowModal(true)
                            } else {
                              resetCart()
                              setCart(user.id)
                              addToCart(product.variant.id, lineItem.qty)
                            }
                          }
                        }
                      }}
                    >
                      <span style={{ fontSize: "0.75rem" }}>
                        {alreadyInCart && cartBelongsToUser ? (
                          <FontAwesomeIcon icon={faCheck} />
                        ) : (
                          "ADD TO ONE-OFF ORDER"
                        )}
                      </span>
                    </MainButton>
                  </Row>
                )}
              </Col>
            </Row>
          </Col>,
        )
      }
    })
  }

  if (isEdit) {
    mappedLineItems.push(
      <React.Fragment
        key={mappedLineItems.length ? mappedLineItems.length + 1 : 0}
      >
        <Col
          key={mappedLineItems.length ? mappedLineItems.length + 1 : 0}
          className="product-item-col add-new-item-col py-3 px-2 mb-2 d-flex flex-column justify-content-center align-items-center"
          onClick={() => {
            setShowProductModal(true)
          }}
        >
          <Row>
            <span className="fs-5">{"Add new products"}</span>
          </Row>
          <Row>
            <FontAwesomeIcon className="fs-5" icon={faCirclePlus} />
          </Row>
        </Col>
        {(mappedLineItems.length === 1 ||
          mappedLineItems.length === 4 ||
          mappedLineItems.length === 7) && (
          <Col className="d-none display-block-xxxl product-item-col py-3 px-2 mb-2 opacity-0"></Col>
        )}
      </React.Fragment>,
    )
  }

  if (
    !isEdit &&
    (mappedLineItems.length === 2 ||
      mappedLineItems.length === 5 ||
      mappedLineItems.length === 8)
  ) {
    mappedLineItems.push(
      <Col
        key={mappedLineItems.length ? mappedLineItems.length + 1 : 0}
        className="d-none display-block-xxxl product-item-col py-3 px-2 mb-2 opacity-0 user-select-none"
      ></Col>,
    )
  }

  return (
    <>
      <ProductSelectorModal
        show={showProductModal}
        setShow={setShowProductModal}
        gender={getGender(userProfileState?.userGender || user?.userGender)}
        loading={permacartLoading}
        context="preferences"
        preferencesState={localPermacart}
        updatePreferencesState={setLocalPermacart}
      ></ProductSelectorModal>
      {mappedLineItems}
    </>
  )
}

export default UserPermacartItems
