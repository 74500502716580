import React, { useRef } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Col, Container, Image, Navbar, Row } from "react-bootstrap"
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom"
import Cormie_logo_header from "../assets/images/Cormie_logo_header.svg"
import { faBasketShopping } from "@fortawesome/free-solid-svg-icons"
import MainButton from "../components/MainButton"
import HeaderBreadcrumb from "../components/HeaderBreadcrumb"
import logout from "../utils/user/logout"
import {
  useAllOrgClientsQuery,
  useLocalUserQuery,
  useProductCatalogueQuery,
} from "../generated/graphql"
import Loading from "../components/Loading"
import isCarerVar from "../apollo/vars/isCarerVar"
import parseGroups from "../utils/user/parseGroups"
import isClientVar from "../apollo/vars/isClientVar"
import productSizesVar from "../apollo/vars/productSizesVar"
import processSizeData from "../utils/product/processSizeData"
import productCatalogueVar from "../apollo/vars/productCatalogueVar"
import processCatalogueData from "../utils/product/processCatalogueData"
import productCategoryVar from "../apollo/vars/productCategoryVar"
import processCategoryData from "../utils/product/processCategoryData"
import CartWidget from "../components/CartWidget"
import processCountryData from "../utils/user/processCountryData"
import countryVar from "../apollo/vars/countryVar"
import getCartCount from "../utils/cart/getCartCount"
import { faNoteSticky, faUser } from "@fortawesome/free-regular-svg-icons"
import isAdminVar from "../apollo/vars/isAdminVar"
import organisationIdVar from "../apollo/vars/organisationIdVar"

const BaseLayout: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const sidebarRef = useRef<HTMLDivElement>(null)
  const isCarer = isCarerVar()
  const isClient = isClientVar()
  const isAdmin = isAdminVar()

  const splitPath = location.pathname.split("/")

  // We eager load this data for the Customer Center page
  // until we can find a better way to fetch this data in Craft
  useAllOrgClientsQuery({
    skip: !isCarer || !isAdmin,
  })

  const { data, loading: localUserLoading } = useLocalUserQuery({
    onCompleted: (data) => {
      countryVar(processCountryData(data))

      const localUserOrg = data?.me?.organisation[0]
      if (localUserOrg?.__typename === "organisation_Category")
        organisationIdVar(localUserOrg.id || "")
      if (data?.me) parseGroups(data.me.groups)
      if (!data?.me) {
        logout()
        navigate("login")
      }
    },
  })

  useProductCatalogueQuery({
    onCompleted: (data) => {
      productSizesVar(processSizeData(data))
      productCatalogueVar(processCatalogueData(data))
      productCategoryVar(processCategoryData(data))
    },
  })

  const handleNavbarToggle = () => {
    sidebarRef.current?.classList.contains("nav-show")
      ? sidebarRef.current.classList.remove("nav-show")
      : sidebarRef.current?.classList.add("nav-show")
  }

  if (
    !localUserLoading &&
    location.pathname === "/" &&
    (isCarer || isClient || isAdmin)
  ) {
    isCarer || isAdmin ? navigate("/customers") : navigate("/profile")
  }

  const SideNav: React.FC = () => {
    return (
      <>
        <Col
          ref={sidebarRef}
          className="mobile-nav-side min-vh-100 d-flex flex-column"
        >
          <div className="mobile-nav-wrapper d-flex flex-column min-vh-100">
            <Row className="d-lg-flex d-none padding-left-30 px-4">
              <Col>
                <Image
                  src={Cormie_logo_header}
                  fluid
                  className="nav-logo py-4 w-75"
                  alt="Cormie Logo Sidebar"
                  onClick={() => {
                    navigate(isCarer || isAdmin ? "/customers" : "/profile")
                  }}
                />
              </Col>
            </Row>
            <Row className="d-lg-flex d-none">
              <Col>
                <hr className="sidebar-nav-break" />
              </Col>
            </Row>
            {localUserLoading ? (
              <Loading />
            ) : (
              <>
                <Row className="padding-left-30 px-4 mt-lg-0 mobile-spacer ">
                  <Col>
                    <i>{"Welcome,"}</i>
                    <Link to={"/profile"}>
                      <p className="welcome-user-name">{data?.me?.fullName}</p>
                    </Link>
                  </Col>
                </Row>
                {/* {(isCarer || isAdmin) && <></>} // To be used for the rest of the nav section */}
                {(isCarer || isAdmin) && (
                  <>
                    <Row className="padding-left-30 py-2 px-4">
                      <Col>
                        <Link to="/orders">
                          <p>
                            <FontAwesomeIcon
                              icon={faNoteSticky}
                              className="me-2"
                            />
                            {"ALL ORDERS"}
                          </p>
                        </Link>
                        <Link to="/customers">
                          <p>
                            <FontAwesomeIcon icon={faUser} className="me-2" />
                            {"CUSTOMER CENTRE"}
                          </p>
                        </Link>
                        <Link to="/employees">
                          <p>
                            <FontAwesomeIcon icon={faUser} className="me-2" />
                            {"EMPLOYEES"}
                          </p>
                        </Link>
                      </Col>
                    </Row>
                    <Row className="px-4">
                      <Col className="d-flex flex-column">
                        <MainButton
                          color="blue"
                          className="text-nowrap px-0 menu-button-max-width"
                          onClick={() => {
                            navigate("/customers/create-customer")
                          }}
                        >
                          <b>{"ADD NEW CUSTOMER"}</b>
                        </MainButton>
                        <MainButton
                          color="blue"
                          className="text-nowrap px-0 menu-button-max-width"
                          onClick={() => {
                            navigate("/employees/create-employee")
                          }}
                        >
                          <b>{"ADD NEW EMPLOYEE"}</b>
                        </MainButton>
                      </Col>
                    </Row>
                  </>
                )}
                {/* {(isCarer || isAdmin) && <></>} // Ends here */}
                <div className={"d-block d-lg-none"}>
                  <Row className="px-4">
                    <Col className="d-flex flex-column">
                      <MainButton className="menu-button-max-width">
                        <CartWidget noCart ignorePath />
                      </MainButton>
                    </Col>
                  </Row>
                  <Row className="px-4">
                    <Col className="d-flex flex-column">
                      <MainButton
                        color="blue"
                        className="menu-button-max-width"
                        onClick={() => {
                          navigate("/cart")
                        }}
                      >
                        <a>
                          <span className="border-end pe-2">{"CART"}</span>
                          <span className="ms-2 me-1">{getCartCount()}</span>
                          <FontAwesomeIcon icon={faBasketShopping} />
                        </a>
                      </MainButton>
                    </Col>
                  </Row>
                </div>
                <Row className="nav-footer-text px-4 text-center justify-content-center mt-auto mb-3">
                  <Col className="border-end border-dark">
                    <Link to={"profile"}>
                      <p className="mx-2 my-0">{"PROFILE"}</p>
                    </Link>
                  </Col>
                  <Col>
                    <a
                      onClick={() => {
                        logout()
                        navigate("/login")
                      }}
                    >
                      <p className="mx-2 my-0">{"LOG OUT"}</p>
                    </a>
                  </Col>
                </Row>
              </>
            )}
          </div>
        </Col>
      </>
    )
  }

  const PageHeader: React.FC = () => {
    return (
      <>
        <div
          className={
            (splitPath.includes("customers")
              ? "customer-center-padding "
              : "new-order-offset ") + "cart-wrapper d-none d-lg-block"
          }
        >
          <CartWidget />
        </div>
        <Row className="w-100 justify-content-start">
          <Col className="breadcrumb-header">
            <HeaderBreadcrumb />
          </Col>
        </Row>
        <Row className="w-100">
          <hr />
        </Row>
      </>
    )
  }

  const RenderedRoute: React.FC = () => {
    return (
      <Row className="w-100">
        {/* Outlet will render all matching child routes from the Router.tsx file */}
        <Outlet />
      </Row>
    )
  }

  const NavbarTop: React.FC = () => {
    return (
      <Navbar
        bg="light"
        className="mobile-navbar-top d-lg-none min-vw-100"
        expand="lg"
      >
        <Container fluid className="ps-0">
          <Navbar.Brand>
            <img
              height={50}
              src={Cormie_logo_header}
              className="nav-logo padding-left-30 d-inline-block align-top"
              alt="Cormie Logo Navbar"
              onClick={() => {
                navigate(isCarer || isAdmin ? "/customers" : "/profile")
              }}
            />
          </Navbar.Brand>
          <button
            className="navbar-toggler"
            type="button"
            aria-controls="navbarToggleExternalContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={handleNavbarToggle}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </Container>
      </Navbar>
    )
  }

  return (
    <>
      <NavbarTop /> {/* Top sticky navbar for mobile */}
      <Container fluid className="d-flex min-vh-100 p-0">
        <SideNav /> {/* Sidebar menu */}
        <Col className="mobile-spacer mt-lg-0 main-content-col">
          <PageHeader /> {/* Global page header */}
          {localUserLoading ? (
            <Loading />
          ) : (
            <>
              <RenderedRoute /> {/* React-router matched route */}
            </>
          )}
        </Col>
      </Container>
    </>
  )
}

export default BaseLayout
