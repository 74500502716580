import React from "react"
import mapTags from "../utils/render/mapTags"

const UserTags: React.FC<{
  userTags: (string | null)[] | null | undefined
}> = ({ userTags }) => {
  let renderedTags: (JSX.Element | undefined)[] = []
  if (userTags) {
    renderedTags = mapTags(
      userTags,
      "non-table-tags fs-6 d-flex align-items-center fw-normal mb-2 me-1",
    )
  }
  return (
    <>
      {renderedTags?.length ? (
        renderedTags
      ) : (
        <>{"No tags associated with this user"}</>
      )}
    </>
  )
}

export default UserTags
