import cartVar from "../../apollo/vars/cartVar"

const getCartCount = () => {
  const currentCart = cartVar()
  const itemIds = Object.keys(currentCart.cart)

  if (itemIds.length > 0) {
    let count = 0
    itemIds.forEach((id) => {
      count += currentCart.cart[id]
    })
    return count
  } else {
    return 0
  }
}

export default getCartCount
