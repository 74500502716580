import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import DatePicker from "react-datepicker"
import { Col, Form, Row } from "react-bootstrap"
import Loading from "./Loading"
import MainButton from "./MainButton"
import {
  EditProfileMutationVariables,
  UserDetailsFragment,
} from "../generated/graphql"
import CormiePillBadge from "./CormiePillBadge"
import cartVar from "../apollo/vars/cartVar"
import GenericBoolModal from "./GenericBoolModal"
import { useNavigate } from "react-router-dom"
import setCart from "../utils/cart/setCart"
import parseInterval from "../utils/permacart/parseIntervalDays"
import UserPermacartItems from "./UserPermacartItems"
import getLocalPermacart from "../utils/user/getLocalPermacart"
import { useReactiveVar } from "@apollo/client"
import ClearCartContent from "./ClearCartContent"
import { faCirclePlay, faCircleStop } from "@fortawesome/free-regular-svg-icons"
import { faCirclePlus, faTrash } from "@fortawesome/pro-regular-svg-icons"
import "react-datepicker/dist/react-datepicker.css"

export type LocalPermacartType =
  | {
      id: string
      qty: number
    }[]
  | undefined

const UserPermacart: React.FC<{
  permacartLineItems:
    | UserDetailsFragment["permacartLineItems"]
    | null
    | undefined
  permacartLoading: boolean
  permacartOrderDayInterval: number | null | undefined
  permacartTriggersInNDays: number | null | undefined
  user: UserDetailsFragment | null | undefined
  userType: "client" | "carer" | "local"
  isEdit?: boolean
  userProfileState?: EditProfileMutationVariables & {
    routeTags: (string | null)[]
  }
  setUserProfileState?: React.Dispatch<
    React.SetStateAction<
      EditProfileMutationVariables & { routeTags: (string | null)[] }
    >
  >
}> = ({
  permacartLineItems,
  permacartLoading,
  permacartOrderDayInterval,
  permacartTriggersInNDays,
  user,
  userType,
  isEdit,
  userProfileState,
  setUserProfileState,
}) => {
  useReactiveVar(cartVar)

  // Component constants
  const dateNow = new Date()
  const navigate = useNavigate()
  const cart = cartVar()
  const cartBelongsToUser = cart.uid === user?.id
  const cartHasItems = Object.keys(cart.cart).length > 0
  const intervalTerm = parseInterval(permacartOrderDayInterval)
    .split(" ")
    .slice(-1)[0]
  const profileOrderInt =
    permacartOrderDayInterval && permacartTriggersInNDays
      ? new Date(
        dateNow.setDate(dateNow.getDate() + permacartTriggersInNDays),
      ).getDay()
      : new Date().getDay()

  // Component state setup
  const [showModal, setShowModal] = useState<boolean>()
  const [modalCallback, setModalCallback] = useState<(() => void) | undefined>()
  const [localPermacart, setLocalPermacart] = useState<LocalPermacartType>()
  const [calendricalUnit, setCalendricalUnit] = useState(
    intervalTerm === "week" ||
      intervalTerm === "weeks" ||
      !intervalTerm ||
      intervalTerm === "Stopped"
      ? "Weeks"
      : "Months",
  )
  const [lastSelectedCalendricalUnit, setLastSelectedCalendricalUnit] =
    useState(
      intervalTerm === "week" ||
        intervalTerm === "weeks" ||
        !intervalTerm ||
        intervalTerm === "Stopped"
        ? "Weeks"
        : "Months",
    )
  const [calendricalValue, setCalendricalValue] = useState(
    permacartOrderDayInterval || 0,
  )
  const [lastSelectedCalendricalValue, setLastSelectedCalendricalValue] =
    useState(permacartOrderDayInterval || 0)
  const [orderDayInt, setOrderDayInt] = useState(profileOrderInt)

  // Component use effects
  useEffect(() => {
    if (!localPermacart) {
      setLocalPermacart(getLocalPermacart(permacartLineItems))
    }
    if (setUserProfileState && Array.isArray(localPermacart)) {
      const optionalState: any = {}
      if (localPermacart.length === 0) {
        setCalendricalValue(0)
        setLastSelectedCalendricalValue(0)
        optionalState["permacartOrderBaseDate"] = null
        optionalState["permacartOrderDayInterval"] = 0
      }
      setUserProfileState({
        ...userProfileState,
        purchasables: localPermacart,
        permacartOrderDayInterval: calendricalValue,
        ...optionalState,
      })
    }
    if (localPermacart?.length === 0) {
      setCalendricalValue(0)
      setLastSelectedCalendricalValue(0)
    }
  }, [permacartLineItems, localPermacart, calendricalUnit])

  // The billing component will cause the data to be pulled again, so we need to re-SET the
  // local permacart
  useEffect(() => {
    setLocalPermacart(getLocalPermacart(permacartLineItems))
  }, [user])

  // Component functions
  const checkCart = () => {
    if (user?.id) {
      if (cartBelongsToUser) {
        // There is a local cart running already registered to this user
        // Show the product wizard if the cart is empty
        navigate(
          `/cart${
            Object.keys(cart.cart).length === 0 ? "?selectProduct=1" : ""
          }`,
        )
      } else if (!cart.uid || (cart.uid != user.id && !cartHasItems)) {
        // There is no cart registered, lets create one
        setCart(user.id)
        navigate("/cart?selectProduct=1")
      } else {
        // We check if the user wishes to reset the cart and lose the previous
        // users cart
        setModalCallback(() => () => {
          navigate("/cart?selectProduct=1")
        })
        setShowModal(true)
      }
    }
  }

  const checkPermacartItems = () => {
    if (localPermacart && Array.isArray(localPermacart)) {
      return localPermacart.length > 0
    }
    return false
  }

  const calendricalValues: (JSX.Element | undefined)[] = []
  for (const i of Array.from({ length: 13 }, (_, i) => i)) {
    calendricalValues.push(
      <option key={i} value={calendricalUnit === "Weeks" ? i * 7 : i * 28}>
        {i}
      </option>,
    )
  }

  const handleCalendricalUnitChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const unit = e.target.value
    if (unit != calendricalUnit) {
      setCalendricalUnit(unit)
      setLastSelectedCalendricalUnit(unit)
      if (unit === "Weeks") {
        // going from months to weeks
        const baseCalendricalVal = calendricalValue / 28
        handleCalendricalValueChange(String(baseCalendricalVal * 7))
      } else {
        // going from weeks to months
        const baseCalendricalVal = calendricalValue / 7
        handleCalendricalValueChange(String(baseCalendricalVal * 28))
      }
    }
  }

  const handleCalendricalValueChange = (
    e: React.ChangeEvent<HTMLSelectElement> | string,
  ) => {
    const value = typeof e === "string" ? e : e.target.value
    setCalendricalValue(Number(value))
    setLastSelectedCalendricalValue(Number(value))

    if (setUserProfileState && userProfileState) {
      setUserProfileState({
        ...userProfileState,
        permacartOrderDayInterval: parseInt(value),
      })
    }
  }

  const handlePoNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (userProfileState && setUserProfileState) {
      setUserProfileState({
        ...userProfileState,
        poNumber: e.target.value,
      })
    }
  }

  const handleStartDateChange = (date: Date | null) => {
    let permacartOrderDayInterval = userProfileState?.permacartOrderDayInterval
    if (userProfileState && setUserProfileState) {
      let formattedDate = ""
      if (date) {
        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, "0")
        const day = String(date.getDate()).padStart(2, "0")
        const dayInt = date.getDay()
        setOrderDayInt(dayInt)

        formattedDate = `${year}-${month}-${day}`
      } else {
        permacartOrderDayInterval = 0
        setCalendricalValue(0)
        setLastSelectedCalendricalValue(0)
        setCalendricalUnit("Weeks")
        setLastSelectedCalendricalUnit("Weeks")
      }
      setUserProfileState({
        ...userProfileState,
        permacartOrderBaseDate: formattedDate != "" ? formattedDate : null,
        permacartOrderDayInterval,
      })
    }
  }

  const permacartLastTriggered = new Date(
    user?.permacartOrderLastTriggeredOnDate || "",
  )
  const now = new Date()
  const permacartTriggeredToday =
    `${permacartLastTriggered.getMonth()}-${permacartLastTriggered.getDate()}` ===
    `${now.getMonth()}-${now.getDate()}`

  let permacartDaysString = "Stopped"
  let triggersInNDays = permacartTriggersInNDays // Make the day count non-const so we can adjust it to account for "today" case
  if (triggersInNDays === 0 && !permacartTriggeredToday) {
    permacartDaysString = "Today" // "Today, but hasn't happened yet" case
  }
  if (
    triggersInNDays === 0 &&
    permacartTriggeredToday &&
    permacartOrderDayInterval
  ) {
    triggersInNDays = permacartOrderDayInterval // "Today, has already happened" case
  }
  if (
    permacartOrderDayInterval &&
    typeof triggersInNDays === "number" &&
    triggersInNDays > 0
  ) {
    permacartDaysString = `${triggersInNDays.toString()} day`
    if (triggersInNDays > 1) {
      permacartDaysString += "s"
    }
  }

  const nextOrderInString =
    permacartDaysString === "Stopped" || permacartDaysString === "Today"
      ? "Next order: "
      : "Next order in: "

  const orderHasJustBeenPaused =
    calendricalValue === 0 && lastSelectedCalendricalValue > 0
  const pauseRecurringOrderLabel = orderHasJustBeenPaused
    ? "Resume recurring order"
    : "Stop recurring order"
  const pauseRecurringOrderClass = orderHasJustBeenPaused
    ? "resume-link"
    : "pause-link"

  const calcMinDate = () => {
    const dateNow = new Date(
      new Date().toLocaleString("en-US", { timeZone: "Australia/Adelaide" }),
    )
    // Prevents user from selecting today as the starting date if its after 4pm the same day.
    if (dateNow.getHours() > 7) {
      return new Date(dateNow.setDate(dateNow.getDate() + 1))
    }
    return dateNow
  }

  const minDate = calcMinDate()

  return (
    <>
      {permacartLoading ? (
        <Loading />
      ) : (
        <>
          {/* New Order Modal */}
          <GenericBoolModal
            showGenericBoolModal={showModal}
            onHide={() => {
              setShowModal(false)
            }}
          >
            <ClearCartContent
              setShowModal={setShowModal}
              newUserUid={user?.id}
              callback={modalCallback}
            />
          </GenericBoolModal>

          {(permacartLineItems && permacartLineItems?.length > 0) || isEdit ? (
            <>
              {/* Main Content */}
              {/* Header */}
              {/* All resolutions */}
              {!isEdit && (
                <>
                  {/* Non-edit date selection fields */}
                  <Col className="d-flex align-items-center justify-content-start mb-2">
                    {user?.permacartOrderBaseDate && (
                      <>
                        <h5 className="mb-2">{"Next order: "}</h5>
                        <CormiePillBadge pink className="ms-3 p-2">
                          <h6 className="m-0">
                            {user?.permacartOrderBaseDate
                              ? user.permacartOrderBaseDate
                                .split("-")
                                .reverse()
                                .join("-")
                              : "N/A"}
                          </h6>
                        </CormiePillBadge>
                      </>
                    )}
                  </Col>

                  <Row className="mb-2" xs={1}>
                    <Col>
                      <Row xs={1} md={2}>
                        <Col className="d-flex align-items-center justify-content-start mb-2 pe-2">
                          <h5 className="m-0">{"Repeats every:"}</h5>

                          <span>
                            <CormiePillBadge pink className="ms-3 p-2">
                              <h6 className="m-0">
                                {parseInterval(permacartOrderDayInterval)}
                              </h6>
                            </CormiePillBadge>
                          </span>
                        </Col>
                        <Col className="d-flex align-items-center justify-content-start mb-2">
                          <h5 className="m-0">{nextOrderInString}</h5>
                          <span>
                            <CormiePillBadge
                              blue={permacartDaysString !== "Stopped"}
                              pink={permacartDaysString === "Stopped"}
                              className="ms-3 p-2"
                            >
                              <h6 className="m-0">{permacartDaysString}</h6>
                            </CormiePillBadge>
                          </span>
                        </Col>
                      </Row>
                    </Col>

                    {/* Small resolutions and up */}
                    <Col className="d-none d-sm-flex align-items-center justify-content-start">
                      <h5 className="mb-2">{`Purchase order #: ${
                        user?.poNumber ? user.poNumber : "N/A"
                      }`}</h5>
                    </Col>

                    {/* Small resolutions and down */}
                    <Col className="d-sm-none d-flex align-items-center justify-content-start">
                      <h5 className="mb-2">{`P.O. # ${
                        user?.poNumber ? user.poNumber : "N/A"
                      }`}</h5>
                    </Col>
                  </Row>

                  <hr></hr>
                </>
              )}

              {/* Permacart Items */}
              <Row className="product-item-row justify-content-between mb-2 permacart-col-breakpoints">
                <UserPermacartItems
                  localPermacart={localPermacart}
                  setLocalPermacart={setLocalPermacart}
                  permacartLoading={permacartLoading}
                  isEdit={isEdit}
                  user={user}
                  cartBelongsToUser={cartBelongsToUser}
                  cartHasItems={cartHasItems}
                  setShowModal={setShowModal}
                  setModalCallback={setModalCallback}
                  userProfileState={userProfileState}
                />
              </Row>
              {!isEdit && (
                <Row>
                  <Col className="ps-3">
                    <span className="muted-text">
                      {"* Product quantities can be adjusted in cart"}
                    </span>
                  </Col>
                </Row>
              )}
              <hr></hr>

              {isEdit && (
                <>
                  {/* Edit date selection fields */}
                  {localPermacart?.length === 0 && (
                    <Row className="d-flex align-items-center justify-content-start mb-2">
                      <span className="px-0 text-muted">
                        {
                          "* Add products to the standing order to select a date"
                        }
                      </span>
                    </Row>
                  )}
                  <Row>
                    <Col className="d-flex align-items-center justify-content-start mb-2">
                      <h5 className="m-0">
                        {user?.permacartOrderLastTriggeredOnDate
                          ? "Next order: "
                          : "Begin order: "}
                      </h5>
                      <span className="ms-1">
                        <DatePicker
                          name="startDate"
                          id="startDate"
                          aria-label="startDate"
                          className={"form-control"}
                          disabled={!checkPermacartItems()}
                          selected={
                            userProfileState?.permacartOrderBaseDate
                              ? new Date(
                                userProfileState?.permacartOrderBaseDate,
                              )
                              : null
                          }
                          onChange={handleStartDateChange}
                          isClearable
                          placeholderText="dd/mm/yyyy"
                          minDate={minDate}
                          dateFormat={"dd/MM/yyyy"}
                          filterDate={(date: Date) => {
                            const day = date.getDay()
                            return day !== 0 && day !== 6
                          }}
                        />
                      </span>
                    </Col>
                  </Row>

                  <Row className="mb-2" xs={1}>
                    <Col>
                      <Row xs={1} md={2}>
                        <Col className="d-flex align-items-center justify-content-start mb-2 pe-2">
                          <h5 className="m-0">{"Repeats every:"}</h5>
                          <span className="mx-1">
                            <Form.Select
                              name="calendricalValue"
                              id="calendricalValue"
                              aria-label="calendricalValue"
                              value={calendricalValue}
                              onChange={handleCalendricalValueChange}
                              disabled={
                                !userProfileState?.permacartOrderBaseDate
                              }
                            >
                              {calendricalValues}
                            </Form.Select>
                          </span>
                          <span className="mx-1">
                            <Form.Select
                              name="calendricalUnit"
                              id="calendricalUnit"
                              aria-label="calendricalUnit"
                              onChange={handleCalendricalUnitChange}
                              defaultValue={calendricalUnit ?? "Weeks"}
                              disabled={
                                !userProfileState?.permacartOrderBaseDate
                              }
                            >
                              <option>{"Weeks"}</option>
                              <option>{"Months"}</option>
                            </Form.Select>
                          </span>
                        </Col>
                        <Col className="d-flex align-items-center justify-content-start mb-2">
                          <h5 className="m-0 text-nowrap">{"Repeats on:"}</h5>
                          <span>
                            {!calendricalValue && (
                              <CormiePillBadge pink className="ms-3 p-2">
                                <h6 className="m-0">Stopped</h6>
                              </CormiePillBadge>
                            )}
                            {calendricalValue > 0 && (
                              <Form.Select
                                value={orderDayInt}
                                aria-label={"orderDay"}
                                disabled
                                className="ms-1"
                              >
                                <option value={1}>{"Monday"}</option>
                                <option value={2}>{"Tuesday"}</option>
                                <option value={3}>{"Wednesday"}</option>
                                <option value={4}>{"Thursday"}</option>
                                <option value={5}>{"Friday"}</option>
                              </Form.Select>
                            )}
                          </span>
                        </Col>
                      </Row>
                    </Col>

                    {/* Small resolutions and up */}
                    <Col className="d-none d-sm-flex align-items-center justify-content-start">
                      <h5 className="m-0">{"Purchase order #: "}</h5>
                      <span className="ms-1">
                        <Form.Control
                          aria-label="poNumber"
                          defaultValue={user?.poNumber ? user.poNumber : ""}
                          placeholder={"Purchase order number"}
                          onChange={handlePoNumberChange}
                        />
                      </span>
                    </Col>

                    {/* Small resolutions and down */}
                    <Col className="d-sm-none d-flex align-items-center justify-content-start">
                      <h5 className="m-0">{"P.O # "}</h5>
                      <span className="ms-1">
                        <Form.Control
                          aria-label="poNumberMobile"
                          defaultValue={user?.poNumber ? user.poNumber : ""}
                          placeholder={"Purchase order number"}
                        />
                      </span>
                    </Col>
                  </Row>

                  {/* Footer */}
                  <Row>
                    <Col className="d-flex flex-column flex-sm-row justify-content-end">
                      {(calendricalValue > 0 ||
                        lastSelectedCalendricalValue > 0) && (
                        <span className="text-muted text-end mx-2">
                          <a
                            className={pauseRecurringOrderClass}
                            onClick={() => {
                              if (setUserProfileState && userProfileState) {
                                setUserProfileState({
                                  ...userProfileState,
                                  permacartOrderDayInterval: 0,
                                })
                                if (!orderHasJustBeenPaused) {
                                  setCalendricalUnit("Weeks")
                                  setCalendricalValue(0)
                                } else {
                                  setCalendricalUnit(
                                    lastSelectedCalendricalUnit,
                                  )
                                  setCalendricalValue(
                                    lastSelectedCalendricalValue,
                                  )
                                }
                              }
                            }}
                          >
                            <>
                              {pauseRecurringOrderLabel}
                              <FontAwesomeIcon
                                icon={
                                  !orderHasJustBeenPaused
                                    ? faCircleStop
                                    : faCirclePlay
                                }
                                className="ms-1"
                              />
                            </>
                          </a>
                        </span>
                      )}
                      <span className="text-muted text-end mx-2">
                        <a
                          onClick={() => {
                            setLocalPermacart([])
                          }}
                        >
                          {"Remove all products"}
                          <FontAwesomeIcon icon={faTrash} className="ms-1" />
                        </a>
                      </span>
                    </Col>
                  </Row>
                </>
              )}
            </>
          ) : (
            <>
              <p className="mt-2">{"No recurring products found."}</p>
              <hr></hr>
            </>
          )}
        </>
      )}
      {!isEdit && (
        <>
          <Row>
            <div className="col-xxl-auto col-12 ps-0">
              <MainButton
                color="blue"
                onClick={() => {
                  navigate(
                    userType === "local"
                      ? "/profile/edit-profile/#permacart"
                      : `/customers/${user?.id}/edit-${
                        userType === "client" ? "customer" : "employee"
                      }/#permacart`,
                  )
                }}
              >
                <b>
                  <FontAwesomeIcon icon={faCirclePlus} className={"me-2"} />
                  {(permacartLineItems && permacartLineItems?.length > 0
                    ? "EDIT"
                    : "NEW") + " FAVOURITES & RECURRING"}
                </b>
              </MainButton>
            </div>
            <Col>
              <MainButton color="pink" onClick={checkCart}>
                <b>
                  <FontAwesomeIcon icon={faCirclePlus} className={"me-2"} />
                  {"NEW ONE-OFF ORDER"}
                </b>
              </MainButton>
            </Col>
          </Row>
        </>
      )}
    </>
  )
}

export default UserPermacart
