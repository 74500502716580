import React from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"

const Head: React.FC = () => {
  return (
    <HelmetProvider>
      <Helmet>
        {/* GTM Container */}
        {/* Contains Hotjar config */}
        {process.env.REACT_APP_VERCEL_ENV === "production" && (
          <script>
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-PQFRPTNW');`}
          </script>
        )}
      </Helmet>
    </HelmetProvider>
  )
}

export default Head
