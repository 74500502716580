import React from "react"
import { Col, Row } from "react-bootstrap"
import { UserDetailsFragment } from "../generated/graphql"

const UserOrganisation: React.FC<{
  user: UserDetailsFragment | undefined | null
}> = ({ user }) => {
  const org = user?.organisation ? user.organisation[0] : undefined

  return (
    <>
      {org && org.__typename === "organisation_Category" ? (
        <>
          {org.arNumber && (
            <Row className="mapped-profile-field">
              <Col className="muted-text text-left col-12 col-sm-4">
                {"AR no."}
              </Col>
              <Col className="text-overflow-wrap text-left">{org.arNumber}</Col>
            </Row>
          )}
          {org.title && (
            <Row className="mapped-profile-field">
              <Col className="muted-text text-left col-12 col-sm-4">
                {"Name"}
              </Col>
              <Col className="text-overflow-wrap text-left">{org.title}</Col>
            </Row>
          )}
          {org.abnNumber && (
            <Row className="mapped-profile-field">
              <Col className="muted-text text-left col-12 col-sm-4">
                {"ABN no."}
              </Col>
              <Col className="text-overflow-wrap text-left">
                {org.abnNumber}
              </Col>
            </Row>
          )}
          {org.billingAddress && (
            <Row className="mapped-profile-field">
              <Col className="muted-text text-left col-12 col-sm-4">
                {"Billing address"}
              </Col>
              <Col className="text-overflow-wrap text-left">
                {org.billingAddress}
              </Col>
            </Row>
          )}
          {org.organisationEmail && (
            <Row className="mapped-profile-field">
              <Col className="muted-text text-left col-12 col-sm-4">
                {"Email address"}
              </Col>
              <Col className="text-overflow-wrap text-left">
                {org.organisationEmail}
              </Col>
            </Row>
          )}
        </>
      ) : (
        <>{"No Organisation details on file"}</>
      )}
    </>
  )
}

export default UserOrganisation
