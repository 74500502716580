import React from "react"

const Volume: React.FC<{
  volume: number
  className?: string
}> = ({ volume, className }) => {
  const perCup = 250

  const fullCup = () => {
    return (
      <div className="cup-container full-cup">
        <span className="drop"></span>
        <span className="empty-cup"></span>
        <span className="filling-cup"></span>
      </div>
    )
  }

  const halfCup = () => {
    const remainingVolume = volume % perCup
    let fillHeight = (14 / perCup) * remainingVolume
    fillHeight = fillHeight < 2 ? 2 : fillHeight

    const cupVolume = {
      bottom: `calc(-15px + ${fillHeight}px)`,
    }

    return (
      <div className="cup-container">
        <span className="drop"></span>
        <span className="empty-cup"></span>
        <span className="filling-cup" style={cupVolume}></span>
      </div>
    )
  }

  const renderCups = () => {
    let currentVolume = volume
    const cups = []

    while (currentVolume >= perCup) {
      cups.push(fullCup())
      currentVolume -= perCup
    }

    if (currentVolume > 1) {
      cups.push(halfCup())
    }

    return (
      <>
        {cups.map((cup, i) => (
          <React.Fragment key={i}>{cup}</React.Fragment>
        ))}
      </>
    )
  }

  return (
    <span
      className={`absorbency-count volume-container  ${
        className ? className : ""
      }`}
    >
      {renderCups()}
    </span>
  )
}

export default Volume
