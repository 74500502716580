import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import React, { useState } from "react"
import { useAddCardToUserMutation } from "../generated/graphql"
import Loading from "./Loading"
import MainButton from "./MainButton"
import { ApolloError } from "@apollo/client"

const CardTokeniser: React.FC<{ userId: string }> = ({ userId }) => {
  const stripe = useStripe()
  const stripeElements = useElements()

  const [validationError, setValidationError] = useState({
    error: false,
    message: "",
  })
  const [loading, setLoading] = useState(false)

  const [addCardToUser] = useAddCardToUserMutation()

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setLoading(true)
    setValidationError({ error: false, message: "" })

    if (!stripe || !stripeElements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    const cardNumber = stripeElements.getElement("cardNumber")

    if (cardNumber) {
      const result = await stripe.createToken(cardNumber)

      if (result.error) {
        setValidationError({
          error: true,
          message:
            result.error.message ||
            "Please check your card details and try again.",
        })
      } else {
        try {
          await addCardToUser({
            variables: {
              userId: userId,
              nickname: "primaryCard",
              token: result.token.id,
            },
            refetchQueries: ["userBillingDetails"],
          })
        } catch (error: any) {
          if (error instanceof ApolloError) {
            setValidationError({
              error: true,
              message: error.message,
            })
          } else {
            throw error
          }
        }
      }
    } else {
      setValidationError({
        error: true,
        message: "Please check your card details and try again.",
      })
    }
    setLoading(false)
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className={"mb-2"}>
        <span>{"Card no. "}</span>
        <span>
          <CardNumberElement />
        </span>
      </div>
      <div className={"mb-2"}>
        <span>{"Exp. "}</span>
        <span>
          <CardExpiryElement />
        </span>
      </div>
      <div className={"mb-2"}>
        <span>{"CVC "}</span>
        <span>
          <CardCvcElement />
        </span>
      </div>
      {validationError.error && (
        <div>
          <h6
            style={{
              color: "red",
            }}
          >
            {validationError.message}
          </h6>
        </div>
      )}
      <MainButton disabled={!stripe || loading} color="blue" className={"mt-2"}>
        {!stripe || loading ? <Loading /> : <>{"Add card"}</>}
      </MainButton>
    </form>
  )
}

export default CardTokeniser
