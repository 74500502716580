import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Button } from "react-bootstrap"

const MainButton: React.FC<{
  icon?: IconDefinition
  color?: "pink" | "blue" | "red-gradient" | "grey"
  faded?: boolean
  invert?: boolean
  nonReactive?: boolean
  className?: string
  onClick?: () => void
  type?: "button" | "submit" | "reset" | undefined
  disabled?: boolean
  pressed?: boolean
  children: React.ReactNode
}> = ({
  icon,
  color = "pink",
  faded = false,
  invert = false,
  nonReactive,
  className,
  onClick = () => undefined,
  type,
  disabled,
  children,
  pressed,
}) => {
  return (
    <Button
      className={
        (className ? className + " " : "") +
        (color + " ") +
        (faded ? "faded " : "") +
        (invert ? "invert " : "") +
        (nonReactive ? "non-reactive " : "") +
        (pressed ? "pressed " : "") +
        "main-button"
      }
      onClick={() => {
        onClick()
      }}
      type={type}
      disabled={disabled}
    >
      {icon && <FontAwesomeIcon icon={icon} className="me-2" />}
      <div className="py-1">{children}</div>
    </Button>
  )
}

export default MainButton
