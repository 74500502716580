import cartVar from "../../apollo/vars/cartVar"
import writeCartToLocalStorage from "./writeCartToLocalStorage"

const removeFromCart = (id: string, quantity?: number) => {
  const currentCart = cartVar()

  if (Object.keys(currentCart.cart).includes(id)) {
    if (quantity && !(currentCart.cart[id] - quantity <= 0)) {
      currentCart.cart[id] = currentCart.cart[id] - quantity
    } else {
      delete currentCart.cart[id]
    }
    cartVar({ ...currentCart })
    writeCartToLocalStorage(currentCart)
  }
}

export default removeFromCart
