import React from "react"
import { Route, Routes } from "react-router-dom"
import BaseLayout from "../layouts/BaseLayout"
import FourOhFour from "../pages/FourOhFour"
import Login from "../pages/Login"
import ProtectedRoute from "./ProtectedRoute"
import CustomerCentre from "../pages/CustomerCentre"
import Profile from "../pages/Profile"
import EditProfile from "../pages/EditProfile"
import AllOrders from "../pages/AllOrders"
import AllEmployees from "../pages/AllEmployees"
import SingleOrder from "../pages/SingleOrder"
import Cart from "../pages/Cart"
import Loading from "../components/Loading"

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="login" element={<Login />} />

      <Route element={<ProtectedRoute />}>
        <Route element={<BaseLayout />}>
          <Route path="/" element={<Loading />} />
          <Route path="customers" element={<CustomerCentre />} />
          <Route path="customers/:uid" element={<Profile user="client" />} />
          <Route
            path="/customers/:uid/edit-customer"
            element={<EditProfile user="client" />}
          />
          <Route
            path="customers/create-customer"
            element={<EditProfile user="client" isCreate />}
          />

          <Route path="employees" element={<AllEmployees />} />
          <Route path="employees/:uid" element={<Profile user="carer" />} />
          <Route
            path="employees/:uid/edit-employee"
            element={<EditProfile user="carer" />}
          />
          <Route
            path="employees/create-employee"
            element={<EditProfile user="carer" isCreate />}
          />
          <Route path="orders" element={<AllOrders />} />
          <Route path="orders/:uid" element={<SingleOrder />} />

          <Route path="profile" element={<Profile user="local" />} />
          <Route
            path="profile/edit-profile"
            element={<EditProfile user="local" />}
          />

          <Route path="cart" element={<Cart />} />

          {/* 404 should always be last */}
          <Route path="*" element={<FourOhFour />} />
        </Route>
      </Route>
    </Routes>
  )
}

export default AppRoutes
