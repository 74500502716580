import React from "react"

const ErrorMessage: React.FC<{ message?: string; touched?: boolean }> = ({
  message,
  touched,
}) => {
  return (
    <>
      {message && touched && (
        <div id="requiredError" style={{ color: "red" }}>
          {message}
        </div>
      )}
    </>
  )
}

export default ErrorMessage
