import React, { useEffect, useState } from "react"
import SearchTags from "./SearchTags"
import {
  EditProfileMutationVariables,
  useAllOrgClientsQuery,
  UserDetailsFragment,
} from "../generated/graphql"
import Loading from "./Loading"

const EditUserTags: React.FC<{
  userProfile: UserDetailsFragment | null | undefined
  userMutationState: EditProfileMutationVariables & {
    routeTags: (string | null)[]
  }
  setUserMutationState: React.Dispatch<
    React.SetStateAction<
      EditProfileMutationVariables & { routeTags: (string | null)[] }
    >
  >
}> = ({ userProfile, userMutationState, setUserMutationState }) => {
  const [localTags, setLocalTags] = useState<Record<string, boolean>>({})

  const { loading } = useAllOrgClientsQuery({
    onCompleted: (data) => {
      const tagObj: Record<string, boolean> = {}
      data.getAllRouteTagNamesInPool.forEach((tag) => {
        if (tag) {
          tagObj[tag] = userProfile?.routeTags
            ? userProfile.routeTags.includes(tag)
            : false
        }
      })
      setLocalTags({ ...tagObj })
    },
  })

  useEffect(() => {
    const selectedTags: string[] = []
    Object.entries(localTags).forEach((kv) => {
      if (kv[1]) {
        selectedTags.push(kv[0])
      }
    })
    setUserMutationState({
      ...userMutationState,
      routeTags: selectedTags,
    })
  }, [localTags])

  return loading ? (
    <Loading />
  ) : (
    <SearchTags tags={localTags} setTags={setLocalTags} />
  )
}

export default EditUserTags
