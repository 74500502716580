import React, { useEffect } from "react"
import { Col, Row } from "react-bootstrap"
import countryVar from "../apollo/vars/countryVar"
import {
  EditProfileMutationVariables,
  UserDetailsFragment,
} from "../generated/graphql"
import { Field, Form, Formik } from "formik"
import { clientValidationSchema } from "../pages/EditProfile"
import zeroPad from "../utils/general/zeroPad"
import ErrorMessage from "./ErrorMessage"

const UserInformation: React.FC<{
  user: UserDetailsFragment | undefined | null
  isEdit?: boolean
  isCreate?: boolean
  isOrderPage?: boolean
  setInputFields?: React.Dispatch<
    React.SetStateAction<
      EditProfileMutationVariables & { routeTags: (string | null)[] }
    >
  >
  inputFields?: EditProfileMutationVariables & { routeTags: (string | null)[] }
}> = ({ user, isEdit, isCreate, isOrderPage, setInputFields, inputFields }) => {
  const countries = countryVar()

  useEffect(() => {
    if (inputFields && setInputFields) {
      const defaultFields: { [key: string]: any } = {}
      if (!inputFields.countryId) defaultFields.countryId = 13
      if (!inputFields.countryId) defaultFields.stateId = 5
      if (!inputFields.userGender) defaultFields.userGender = "Female"
      // if (!inputFields.dobYear) defaultFields.dobYear = new Date().getFullYear()
      // if (!inputFields.dobMonth)
      //   defaultFields.dobMonth = new Date().getMonth() + 1
      // if (!inputFields.dobDay) defaultFields.dobDay = new Date().getDate()
      if (Object.keys(defaultFields).length > 0) {
        setInputFields({ ...inputFields, ...defaultFields })
      }
    }
  }, [inputFields])

  let calculatedAge = 0
  if (user?.dobDay && user?.dobMonth && user?.dobYear) {
    const birthDate = new Date(
      Date.parse(`${user.dobYear}-${user.dobMonth}-${user.dobDay}`),
    )
    const currentDate = new Date()
    calculatedAge = currentDate.getFullYear() - birthDate.getFullYear()
  }

  const defaultDate = () => {
    if (user?.dobYear && user?.dobMonth && user?.dobDay) {
      return `${user.dobYear}-${zeroPad(user.dobMonth, 2)}-${zeroPad(
        user.dobDay,
        2,
      )}`
    } else {
      // const dateNow = new Date()
      // return `${dateNow.getFullYear()}-${zeroPad(
      //   dateNow.getMonth() + 1,
      //   2,
      // )}-${zeroPad(dateNow.getDate(), 2)}`
      ("dd/mm/yyyy")
    }
  }

  const mutationUpdate = (field: string, value: string) => {
    if (inputFields && setInputFields) {
      const updateState = true
      // const requiredFields = [
      //   "fullName",
      //   "userGender",
      //   "dobDate",
      //   "carersCentrePhoneNumber",
      //   "address1",
      //   "city",
      //   "countryId",
      //   "stateId",
      //   "zipCode",
      // ]
      // const numberFields = ["countryId", "stateId", "zipCode"]

      const conversionFields = ["countryId", "stateId"]

      // const removeSpaces: string[] = []

      if (field === "dobDate") {
        // handle DOB special case
        let allNumbers = true
        const splitDate = value.split("-")
        splitDate.forEach((val: string) => {
          if (!Number(val)) allNumbers = false
        })
        if (allNumbers) {
          setInputFields({
            ...inputFields,
            dobYear: parseInt(splitDate[0]),
            dobMonth: parseInt(splitDate[1]),
            dobDay: parseInt(splitDate[2]),
          })
        }
      } else {
        // handle all other fields
        // if the field is req'd and not present, don't process
        // if (requiredFields.includes(field) && !value) updateState = false

        // if the field needs to be a number and isn't, dont process
        // if (numberFields.includes(field) && !Number(value)) updateState = false

        // if (field === "email" && !(value.includes(".") && value.includes("@")))
        //   updateState = false

        // if (removeSpaces.includes(field)) value = value.replace(" ", "")

        if (updateState) {
          setInputFields({
            ...inputFields,
            [field]: conversionFields.includes(field) ? Number(value) : value,
          })
        }
      }
    }
  }

  return (
    <Formik
      initialValues={{
        firstName: inputFields?.firstName || "",
        lastName: inputFields?.lastName || "",
        dobDate: undefined,
        countryId: inputFields?.countryId || 13,
        stateId: inputFields?.stateId || 5,
        userGender: inputFields?.userGender || "Female",
        carersCentrePhoneNumber: inputFields?.carersCentrePhoneNumber || "",
        email: inputFields?.email || "",
        address1: inputFields?.address1 || "",
        address2: inputFields?.address2 || "",
        city: inputFields?.city || "",
        zipCode: inputFields?.zipCode || "",
        clientId1: inputFields?.clientId1 || "",
        clientId2: inputFields?.clientId2 || "",
        ndisPackageId: inputFields?.ndisPackageId || "",
      }}
      validationSchema={clientValidationSchema}
      onSubmit={() => {
        null
      }}
    >
      {({ errors, touched, setFieldValue }) => (
        <Form>
          {/* Name */}
          {isEdit ? (
            <>
              <Row className="mapped-profile-field">
                <Col className="muted-text text-left d-flex align-items-center col-12 col-sm-4">
                  {"First name"}
                </Col>
                <Col className="text-overflow-wrap profile-input-field text-left">
                  <Field
                    id="firstName"
                    name="firstName"
                    onChange={(e: React.ChangeEvent<any>) => {
                      setFieldValue(e.target.name, e.target.value)
                      mutationUpdate(e.target.name, e.target.value)
                    }}
                    className={
                      (errors.firstName && touched.firstName
                        ? "is-invalid"
                        : "") + " form-control"
                    }
                  />
                  <ErrorMessage
                    message={errors.firstName}
                    touched={touched.firstName}
                  />
                </Col>
              </Row>
              <Row className="mapped-profile-field">
                <Col className="muted-text text-left d-flex align-items-center col-12 col-sm-4">
                  {"Last name"}
                </Col>
                <Col className="text-overflow-wrap profile-input-field text-left">
                  <Field
                    id="lastName"
                    name="lastName"
                    onChange={(e: React.ChangeEvent<any>) => {
                      setFieldValue(e.target.name, e.target.value)
                      mutationUpdate(e.target.name, e.target.value)
                    }}
                    className={
                      (errors.lastName && touched.lastName
                        ? "is-invalid"
                        : "") + " form-control"
                    }
                  />
                  <ErrorMessage
                    message={errors.lastName}
                    touched={touched.lastName}
                  />
                </Col>
              </Row>
            </>
          ) : (
            <Row className="mapped-profile-field">
              <Col className="muted-text text-left d-flex align-items-center col-12 col-sm-4">
                {"Name"}
              </Col>
              <Col className="text-overflow-wrap profile-input-field text-left">
                <>{user?.fullName}</>
              </Col>
            </Row>
          )}
          {/* Gender */}
          {!isOrderPage && (
            <Row className="mapped-profile-field">
              <Col className="muted-text text-left d-flex align-items-center col-12 col-sm-4">
                {"Gender"}
              </Col>
              <Col className="text-overflow-wrap profile-input-field text-left">
                {isEdit ? (
                  <>
                    <Field
                      as="select"
                      id="userGender"
                      name="userGender"
                      onChange={(e: React.ChangeEvent<any>) => {
                        setFieldValue(e.target.name, e.target.value)
                        mutationUpdate(e.target.name, e.target.value)
                      }}
                      className={
                        (errors.userGender && touched.userGender
                          ? "is-invalid"
                          : "") + " form-select"
                      }
                    >
                      <option value="Female">{"Female"}</option>
                      <option value="Male">{"Male"}</option>
                    </Field>
                    <ErrorMessage
                      message={errors.userGender}
                      touched={touched.userGender}
                    />
                  </>
                ) : (
                  <>{user?.userGender}</>
                )}
              </Col>
            </Row>
          )}
          {/* Age */}
          {!isEdit && !isOrderPage && calculatedAge != 0 && (
            <Row className="mapped-profile-field">
              <Col className="muted-text text-left d-flex align-items-center col-12 col-sm-4">
                {"Age"}
              </Col>
              <Col className="text-overflow-wrap profile-input-field text-left">
                {calculatedAge} years old
              </Col>
            </Row>
          )}
          {/* Date of Birth */}
          {!isOrderPage && (
            <>
              {user?.dobDay && user?.dobMonth && user?.dobYear && (
                <Row className="mapped-profile-field">
                  <Col className="muted-text text-left d-flex align-items-center col-12 col-sm-4">
                    {"DOB"}
                  </Col>
                  <Col className="text-overflow-wrap profile-input-field text-left">
                    {isEdit ? (
                      <>
                        <Field
                          type="date"
                          id="dobDate"
                          name="dobDate"
                          onChange={(e: React.ChangeEvent<any>) => {
                            setFieldValue(e.target.name, e.target.value)
                            mutationUpdate(e.target.name, e.target.value)
                          }}
                          className={
                            (errors.dobDate && touched.dobDate
                              ? "is-invalid"
                              : "") + " form-control"
                          }
                        />
                        <ErrorMessage
                          message={errors.dobDate}
                          touched={touched.dobDate}
                        />
                      </>
                    ) : (
                      <>
                        {user?.dobDay} / {user?.dobMonth} / {user?.dobYear}
                      </>
                    )}
                  </Col>
                </Row>
              )}
            </>
          )}
          {/* Phone Number */}
          <Row className="mapped-profile-field">
            <Col className="muted-text text-left d-flex align-items-center col-12 col-sm-4">
              {"Phone"}
            </Col>
            <Col className="text-overflow-wrap profile-input-field text-left">
              {isEdit ? (
                <>
                  <Field
                    id="carersCentrePhoneNumber"
                    name="carersCentrePhoneNumber"
                    onChange={(e: React.ChangeEvent<any>) => {
                      setFieldValue(e.target.name, e.target.value)
                      mutationUpdate(e.target.name, e.target.value)
                    }}
                    className={
                      (errors.carersCentrePhoneNumber &&
                      touched.carersCentrePhoneNumber
                        ? "is-invalid"
                        : "") + " form-control"
                    }
                  />
                  <ErrorMessage
                    message={errors.carersCentrePhoneNumber}
                    touched={touched.carersCentrePhoneNumber}
                  />
                </>
              ) : (
                <>{user?.carersCentrePhoneNumber}</>
              )}
            </Col>
          </Row>
          {!isOrderPage && (
            <>
              {/* Email */}
              <Row className="mapped-profile-field">
                <Col className="muted-text text-left d-flex align-items-center col-12 col-sm-4">
                  {"Email"}
                </Col>
                <Col className="text-overflow-wrap profile-input-field text-left">
                  {isEdit ? (
                    <>
                      <Field
                        id="email"
                        name="email"
                        readOnly={!isCreate}
                        onChange={(e: React.ChangeEvent<any>) => {
                          setFieldValue(e.target.name, e.target.value)
                          mutationUpdate(e.target.name, e.target.value)
                        }}
                        className={
                          (errors.email && touched.email ? "is-invalid" : "") +
                          " form-control"
                        }
                      />
                      <ErrorMessage
                        message={errors.email}
                        touched={touched.email}
                      />
                    </>
                  ) : (
                    <>{user?.email}</>
                  )}
                </Col>
              </Row>
              {/* Client ID 1 */}
              {(user?.clientId1 || isEdit) && (
                <Row className="mapped-profile-field">
                  <Col className="muted-text text-left d-flex align-items-center col-12 col-sm-4">
                    {"Client ID 1"}
                  </Col>
                  <Col className="text-overflow-wrap profile-input-field text-left">
                    {isEdit ? (
                      <>
                        <Field
                          id="clientId1"
                          name="clientId1"
                          onChange={(e: React.ChangeEvent<any>) => {
                            setFieldValue(e.target.name, e.target.value)
                            mutationUpdate(e.target.name, e.target.value)
                          }}
                          className={
                            (errors.clientId1 && touched.clientId1
                              ? "is-invalid"
                              : "") + " form-control"
                          }
                        />
                        <ErrorMessage
                          message={errors.clientId1}
                          touched={touched.clientId1}
                        />
                      </>
                    ) : (
                      <>{user?.clientId1}</>
                    )}
                  </Col>
                </Row>
              )}
              {/* Client ID 2 */}
              {(user?.clientId2 || isEdit) && (
                <Row className="mapped-profile-field">
                  <Col className="muted-text text-left d-flex align-items-center col-12 col-sm-4">
                    {"Client ID 2"}
                  </Col>
                  <Col className="text-overflow-wrap profile-input-field text-left">
                    {isEdit ? (
                      <>
                        <Field
                          id="clientId2"
                          name="clientId2"
                          onChange={(e: React.ChangeEvent<any>) => {
                            setFieldValue(e.target.name, e.target.value)
                            mutationUpdate(e.target.name, e.target.value)
                          }}
                          className={
                            (errors.clientId2 && touched.clientId2
                              ? "is-invalid"
                              : "") + " form-control"
                          }
                        />
                        <ErrorMessage
                          message={errors.clientId2}
                          touched={touched.clientId2}
                        />
                      </>
                    ) : (
                      <>{user?.clientId2}</>
                    )}
                  </Col>
                </Row>
              )}
              {/* NDIS Package ID */}
              {(user?.ndisPackageId || isEdit) && (
                <Row className="mapped-profile-field">
                  <Col className="muted-text text-left d-flex align-items-center col-12 col-sm-4">
                    {"NDIS package ID."}
                  </Col>
                  <Col className="text-overflow-wrap profile-input-field text-left">
                    {isEdit ? (
                      <>
                        <Field
                          id="ndisPackageId"
                          name="ndisPackageId"
                          onChange={(e: React.ChangeEvent<any>) => {
                            setFieldValue(e.target.name, e.target.value)
                            mutationUpdate(e.target.name, e.target.value)
                          }}
                          className={
                            (errors.ndisPackageId && touched.ndisPackageId
                              ? "is-invalid"
                              : "") + " form-control"
                          }
                        />
                        <ErrorMessage
                          message={errors.ndisPackageId}
                          touched={touched.ndisPackageId}
                        />
                      </>
                    ) : (
                      <>{user?.ndisPackageId}</>
                    )}
                  </Col>
                </Row>
              )}
            </>
          )}
          <hr></hr>
          {/* Shipping address title */}
          <Row className="mapped-profile-field">
            <Col className="text-left my-1 subtitle">{"Shipping address"}</Col>
          </Row>
          {/* Address 1 */}
          <Row className="mapped-profile-field">
            <Col className="muted-text text-left d-flex align-items-center col-12 col-sm-4">
              {"Address line 1"}
            </Col>
            <Col className="text-overflow-wrap profile-input-field text-left">
              {isEdit ? (
                <>
                  <Field
                    id="address1"
                    name="address1"
                    onChange={(e: React.ChangeEvent<any>) => {
                      setFieldValue(e.target.name, e.target.value)
                      mutationUpdate(e.target.name, e.target.value)
                    }}
                    className={
                      (errors.address1 && touched.address1
                        ? "is-invalid"
                        : "") + " form-control"
                    }
                  />
                  <ErrorMessage
                    message={errors.address1}
                    touched={touched.address1}
                  />
                </>
              ) : (
                <>{user?.customer?.primaryShippingAddress?.address1}</>
              )}
            </Col>
          </Row>
          {/* Address 2 */}
          {(user?.customer?.primaryShippingAddress?.address2 || isEdit) && (
            <Row className="mapped-profile-field">
              <Col className="muted-text text-left d-flex align-items-center col-12 col-sm-4">
                {"Address line 2"}
              </Col>
              <Col className="text-overflow-wrap profile-input-field text-left">
                {isEdit ? (
                  <>
                    <Field
                      id="address2"
                      name="address2"
                      onChange={(e: React.ChangeEvent<any>) => {
                        setFieldValue(e.target.name, e.target.value)
                        mutationUpdate(e.target.name, e.target.value)
                      }}
                      className={
                        (errors.address2 && touched.address2
                          ? "is-invalid"
                          : "") + " form-control"
                      }
                    />
                    <ErrorMessage
                      message={errors.address2}
                      touched={touched.address2}
                    />
                  </>
                ) : (
                  <>{user?.customer?.primaryShippingAddress?.address2}</>
                )}
              </Col>
            </Row>
          )}
          {/* Suburb */}
          {(user?.customer?.primaryShippingAddress?.city || isEdit) && (
            <Row className="mapped-profile-field">
              <Col className="muted-text text-left d-flex align-items-center col-12 col-sm-4">
                {"Suburb"}
              </Col>
              <Col className="text-overflow-wrap profile-input-field text-left">
                {isEdit ? (
                  <>
                    <Field
                      id="city"
                      name="city"
                      onChange={(e: React.ChangeEvent<any>) => {
                        setFieldValue(e.target.name, e.target.value)
                        mutationUpdate(e.target.name, e.target.value)
                      }}
                      className={
                        (errors.city && touched.city ? "is-invalid" : "") +
                        " form-control"
                      }
                    />
                    <ErrorMessage
                      message={errors.city}
                      touched={touched.city}
                    />
                  </>
                ) : (
                  <>{user?.customer?.primaryShippingAddress?.city}</>
                )}
              </Col>
            </Row>
          )}
          {/* State */}
          <Row className="mapped-profile-field">
            <Col className="muted-text text-left d-flex align-items-center col-12 col-sm-4">
              {"State"}
            </Col>
            <Col className="text-overflow-wrap profile-input-field text-left">
              {isEdit ? (
                <>
                  <Field
                    as="select"
                    id="stateId"
                    name="stateId"
                    onChange={(e: React.ChangeEvent<any>) => {
                      setFieldValue(e.target.name, e.target.value)
                      mutationUpdate(e.target.name, e.target.value)
                    }}
                    className={
                      (errors.stateId && touched.stateId ? "is-invalid" : "") +
                      " form-select"
                    }
                  >
                    {Object.values(
                      countries[
                        user?.customer?.primaryShippingAddress?.countryId || 13
                      ].states,
                    ).map((value, index) => {
                      return (
                        <option value={parseInt(value.id)} key={index}>
                          {value.abbreviation}
                        </option>
                      )
                    })}
                  </Field>
                  <ErrorMessage
                    message={errors.stateId}
                    touched={touched.stateId}
                  />
                </>
              ) : (
                <>
                  {user?.customer?.primaryShippingAddress?.stateId &&
                    user?.customer?.primaryShippingAddress?.countryId &&
                    countries[user.customer.primaryShippingAddress.countryId]
                      .states[user.customer.primaryShippingAddress.stateId]
                      .abbreviation}
                </>
              )}
            </Col>
          </Row>
          {/* Country */}
          {!isOrderPage && (
            <Row className="mapped-profile-field">
              <Col className="muted-text text-left d-flex align-items-center col-12 col-sm-4">
                {"Country"}
              </Col>
              <Col className="text-overflow-wrap profile-input-field text-left">
                {isEdit ? (
                  <>
                    <Field
                      as="select"
                      id="countryId"
                      name="countryId"
                      onChange={(e: React.ChangeEvent<any>) => {
                        setFieldValue(e.target.name, e.target.value)
                        mutationUpdate(e.target.name, e.target.value)
                      }}
                      className={
                        (errors.countryId && touched.countryId
                          ? "is-invalid"
                          : "") + " form-select"
                      }
                    >
                      {Object.values(countries).map((value, index) => {
                        return (
                          <option value={index} key={index}>
                            {value.name}
                          </option>
                        )
                      })}
                    </Field>
                    <ErrorMessage
                      message={errors.countryId}
                      touched={touched.countryId}
                    />
                  </>
                ) : (
                  <>
                    {user?.customer?.primaryShippingAddress?.countryId &&
                      countries[
                        user.customer.primaryShippingAddress.countryId || 13
                      ].name}
                  </>
                )}
              </Col>
            </Row>
          )}
          {/* Postcode */}
          <Row className="mapped-profile-field">
            <Col className="muted-text d-flex align-items-center text-left col-12 col-sm-4">
              {"Postcode"}
            </Col>
            <Col className="text-overflow-wrap profile-input-field text-left">
              {isEdit ? (
                <>
                  <Field
                    id="zipCode"
                    name="zipCode"
                    onChange={(e: React.ChangeEvent<any>) => {
                      setFieldValue(e.target.name, e.target.value)
                      mutationUpdate(e.target.name, e.target.value)
                    }}
                    className={
                      (errors.zipCode && touched.zipCode ? "is-invalid" : "") +
                      " form-control"
                    }
                  />
                  <ErrorMessage
                    message={errors.zipCode}
                    touched={touched.zipCode}
                  />
                </>
              ) : (
                <>{user?.customer?.primaryShippingAddress?.zipCode}</>
              )}
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

export default UserInformation
