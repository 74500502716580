import { ProductCatalogueVarType } from "../../apollo/vars/productCatalogueVar"
import { ProductCatalogueQuery } from "../../generated/graphql"

const processCatalogueData = (data: ProductCatalogueQuery) => {
  const catalogue: ProductCatalogueVarType = {}

  data.allProducts?.forEach((product) => {
    if (product?.variant?.id) {
      catalogue[product.variant.id] = product
    }
  })

  return catalogue
}

export default processCatalogueData
