import React from "react"
import { Col, Row } from "react-bootstrap"
import PageTitle from "../components/PageTitle"
import SubTitle from "../components/SubTitle"
import CormiePageContainer from "../layouts/CormiePageContainer"
import OrdersInterface from "../components/OrdersInterface"

const AllOrders: React.FC = () => {
  return (
    <CormiePageContainer>
      {/* Header section */}
      <Row className="flex-column flex-md-row">
        <Col>
          <PageTitle className="mb-3">{"All orders"}</PageTitle>
          <SubTitle>{"Search for an order across your organisation"}</SubTitle>
        </Col>
      </Row>

      <OrdersInterface isSearchable pollsForCancellable />
    </CormiePageContainer>
  )
}

export default AllOrders
