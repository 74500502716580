import React from "react"
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Table } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { AllOrgOrderDetailsFragment } from "../generated/graphql"
import CormiePillBadge from "./CormiePillBadge"
import Loading from "./Loading"
import CancelOrderPillBadge from "./CancelOrderPillBadge"
import OrderPaginationControls from "./OrderPaginationControls"

// NOTE: updates made here also need to be made to the Order.php resolver class,
// in the searchStatus method
export const orderStatusArray = {
  order_placed: "Ordered",
  ready_for_pickup: "Ordered",
  in_transit: "In transit",
  with_driver: "With driver",
  insufficient_address: "Insufficient address",
  delivery_failed: "Delivery failed",
  completed: "Completed",
  parcel_completed: "Completed",
  untrackable: "Completed",
  cancelled: "Cancelled",
}

export type PageStateType = {
  show: number
  pageNo: number
  max: number
}

export type OrderSortType = {
  active: boolean
  ascending: boolean
  field: "date" | "name"
}

const OrdersTable: React.FC<{
  ordersData: (AllOrgOrderDetailsFragment | null)[] | undefined | null
  ordersLoading: boolean
  pageState: PageStateType
  setPageState: React.Dispatch<React.SetStateAction<PageStateType>>
  orderSort: OrderSortType
  setOrderSort: React.Dispatch<React.SetStateAction<OrderSortType>>
  withTags?: boolean
  linkNames?: boolean
}> = ({
  ordersData,
  ordersLoading,
  pageState,
  setPageState,
  orderSort,
  setOrderSort,
  withTags = false,
  linkNames = false,
}) => {
  const navigate = useNavigate()

  return (
    <>
      {ordersLoading ? (
        <div className="py-5">
          <Loading />
        </div>
      ) : (
        <>
          <Table responsive className="responsive-table">
            <thead>
              <tr>
                <th className="pe-3 text-nowrap">
                  {"Customer"}
                  <FontAwesomeIcon
                    className={
                      (!orderSort.active || orderSort.field != "name"
                        ? "half-opacity "
                        : "") + "ms-1 cormie-pink-text"
                    }
                    icon={
                      orderSort.active
                        ? orderSort.field === "name"
                          ? orderSort.ascending
                            ? faChevronUp
                            : faChevronDown
                          : faChevronUp
                        : faChevronUp
                    }
                    onClick={() => {
                      setOrderSort({
                        active: true,
                        ascending: !orderSort.ascending,
                        field: "name",
                      })
                    }}
                  />
                </th>
                <th className="pe-2">{"Order no."}</th>
                <th className="pe-3 text-nowrap">
                  {"Date/Time"}
                  <FontAwesomeIcon
                    className={
                      (!orderSort.active || orderSort.field != "date"
                        ? "half-opacity "
                        : "") + "ms-1 cormie-pink-text"
                    }
                    icon={
                      orderSort.active
                        ? orderSort.field === "date"
                          ? orderSort.ascending
                            ? faChevronUp
                            : faChevronDown
                          : faChevronUp
                        : faChevronUp
                    }
                    onClick={() => {
                      setOrderSort({
                        active: true,
                        ascending: !orderSort.ascending,
                        field: "date",
                      })
                    }}
                  />
                </th>
                {withTags && <th className="pe-2">{"Tags"}</th>}
                <th className="pe-2">{"Total"}</th>
                <th>{"Status"}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {ordersData?.map((order, i) => {
                if (order) {
                  // Craft will always store the date against the system timezone (not UTC)
                  // Cormie"s Craft instance is set to the Adelaide timezone, so we pass that explicitly
                  const userTimezone =
                    Intl.DateTimeFormat().resolvedOptions().timeZone

                  const parsedOrderDate = new Date(
                    Date.parse(order.dateOrdered || new Date().toDateString()),
                  )
                  const orderDate =
                    parsedOrderDate.toLocaleDateString("en-US", {
                      day: "numeric",
                      month: "short",
                      year: "2-digit",
                      timeZone: userTimezone,
                    }) +
                    " / " +
                    parsedOrderDate.toLocaleString("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                      timeZone: userTimezone,
                    })

                  return (
                    <React.Fragment key={i}>
                      <tr>
                        <td className="orders-table-client-name-cell">
                          {linkNames ? (
                            <a
                              onClick={() => {
                                navigate(
                                  `/customers/${order.customer?.user?.id}`,
                                )
                              }}
                            >
                              <u>{order.customer?.user?.fullName}</u>
                            </a>
                          ) : (
                            <>{order.customer?.user?.fullName}</>
                          )}
                        </td>
                        <td className="orders-table-order-number-cell">
                          <a
                            onClick={() => {
                              navigate(`/orders/${order.reference}`)
                            }}
                          >
                            <u>{"#" + order.reference}</u>
                          </a>
                        </td>
                        <td className="orders-table-order-date-cell">
                          {orderDate}
                        </td>
                        {withTags && (
                          <td>
                            {order.customer?.user?.routeTags.map((tag, i) => (
                              <CormiePillBadge key={i} blue className="p-1 m-1">
                                {tag}
                              </CormiePillBadge>
                            ))}
                          </td>
                        )}
                        <td>{"$" + order?.total?.toFixed(2)}</td>
                        <td>
                          <CormiePillBadge
                            blue={
                              !(
                                orderStatusArray[
                                  order?.shippitStatus as keyof typeof orderStatusArray
                                ] === "Cancelled"
                              )
                            }
                            pink={
                              orderStatusArray[
                                order?.shippitStatus as keyof typeof orderStatusArray
                              ] === "Cancelled"
                            }
                            className={
                              order?.trackingCode
                                ? orderStatusArray[
                                    order?.shippitStatus as keyof typeof orderStatusArray
                                ] === "Cancelled"
                                  ? "hover-order-cancelled "
                                  : "hover-order "
                                : ""
                            }
                            onClick={() => {
                              if (order.trackingCode)
                                window.open(
                                  `https://app.shippit.com/tracking/${order.trackingCode}`,
                                  "_blank",
                                )
                            }}
                          >
                            <div className="opacity-75">
                              {order?.shippitStatus
                                ? orderStatusArray[
                                    order?.shippitStatus as keyof typeof orderStatusArray
                                ]
                                  ? orderStatusArray[
                                      order?.shippitStatus as keyof typeof orderStatusArray
                                  ]
                                  : "In transit"
                                : "Ordered"}
                            </div>
                          </CormiePillBadge>
                        </td>
                        <td>
                          {order.cancellable === 1 && (
                            <CancelOrderPillBadge order={order} />
                          )}
                        </td>
                      </tr>
                    </React.Fragment>
                  )
                }
              })}
            </tbody>
          </Table>
          {!ordersData?.length && (
            <div className="mb-2">
              {"No orders found based on your search terms"}
            </div>
          )}
        </>
      )}
      <OrderPaginationControls
        pageState={pageState}
        setPageState={setPageState}
      />
    </>
  )
}

export default OrdersTable
