import cartVar from "../../apollo/vars/cartVar"
import writeCartToLocalStorage from "./writeCartToLocalStorage"

const resetCart = () => {
  const resetCart = { cart: {} }
  cartVar(resetCart)
  writeCartToLocalStorage(resetCart)
}

export default resetCart
