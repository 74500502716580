import cartVar from "../../apollo/vars/cartVar"
import writeCartToLocalStorage from "./writeCartToLocalStorage"

const clearCart = () => {
  const currentCart = cartVar()
  const clearedCart = { cart: {}, uid: currentCart.uid }
  cartVar({ ...clearedCart })
  writeCartToLocalStorage(clearedCart)
}

export default clearCart
