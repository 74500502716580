import React from "react"
import { Col, Spinner } from "react-bootstrap"

const Loading: React.FC<{
  className?: string
  small?: boolean
}> = ({ className, small = false }) => {
  return (
    <Col
      className={
        className +
        " d-flex h-100 w-100 align-items-center justify-content-center"
      }
    >
      <Spinner animation="border" role="status" size={small ? "sm" : undefined}>
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </Col>
  )
}

export default Loading
