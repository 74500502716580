import client from "../../apollo/client"
import isLoggedInVar from "../../apollo/vars/isLoggedInVar"
import resetCart from "../cart/resetCart"

const login = (token: string) => {
  localStorage.setItem("token", token)
  isLoggedInVar(true)
  client.resetStore()
  resetCart()
  return true
}

export default login
