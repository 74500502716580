import React from "react"
import { Navigate, Outlet } from "react-router-dom"
import isLoggedInVar from "../apollo/vars/isLoggedInVar"

const ProtectedRoute: React.FC = () => {
  if (isLoggedInVar()) {
    return <Outlet />
  }

  return <Navigate to="/login" replace />
}

export default ProtectedRoute
