import React from "react"
import { Col, Row } from "react-bootstrap"
import cartVar from "../apollo/vars/cartVar"
import { useUserProfileQuery } from "../generated/graphql"
import resetCart from "../utils/cart/resetCart"
import Loading from "./Loading"
import MainButton from "./MainButton"
import setCart from "../utils/cart/setCart"

const ClearCartContent: React.FC<{
  setShowModal: React.Dispatch<React.SetStateAction<boolean | undefined>>
  newUserUid?: string | null | undefined
  callback?: () => void
}> = ({ setShowModal, newUserUid, callback}) => {
  const cart = cartVar()

  const { data, loading } = useUserProfileQuery({
    variables: {
      id: cart.uid || "",
    },
    skip: !cart.uid,
  })

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="mt-3 mx-3">
          <h5 className="mb-3">
            <b>{"New order will clear your cart"}</b>
          </h5>
          <p>
            {"Your cart already contains products for "}
            <b>{data?.extendedUser?.fullName + ". "}</b>
            {"Would you like to "}
            <b>{"clear the cart "}</b>
            {"and start a new order?"}
          </p>
          <Row xs={1} sm={2} className="mt-4">
            <Col className="w-auto">
              <MainButton
                className="m-0"
                onClick={() => {
                  resetCart()
                  if (newUserUid) {
                    setCart(newUserUid)
                  }
                  if (callback) {
                    callback()
                  }
                  setShowModal(false)
                }}
              >
                <b className="px-2">{"START NEW ORDER"}</b>
              </MainButton>
            </Col>
            <Col className="d-flex col-sm-auto align-items-center ps-0 ps-sm-5 pt-4 pt-sm-0">
              <a
                onClick={() => {
                  setShowModal(false)
                }}
              >
                <b>
                  <u>{"CANCEL"}</u>
                </b>
              </a>
            </Col>
          </Row>
        </div>
      )}
    </>
  )
}

export default ClearCartContent
