import client from "../../apollo/client"
import isAdminVar from "../../apollo/vars/isAdminVar"
import isCarerVar from "../../apollo/vars/isCarerVar"
import isClientVar from "../../apollo/vars/isClientVar"
import isLoggedInVar from "../../apollo/vars/isLoggedInVar"

const logout = () => {
  localStorage.removeItem("token")
  isLoggedInVar(false)
  isCarerVar(false)
  isClientVar(false)
  isAdminVar(false)
  client.resetStore()
}

export default logout
