import React from "react"
import CormiePillBadge from "../../components/CormiePillBadge"

const mapTags = (
  tagsToRender: (string | null)[],
  className?: string,
): (JSX.Element | undefined)[] => {
  return tagsToRender.map((tag, i) => {
    if (tag) {
      return (
        <CormiePillBadge key={i} blue className={className}>
          <u>{tag}</u>{" "}
        </CormiePillBadge>
      )
    }
  })
}

export default mapTags
