import { faTrash } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import React, { useEffect, useState } from "react"
import { Col, Form, Row, Spinner } from "react-bootstrap"
import {
  EditProfileMutationVariables,
  PaymentSourceDetailsFragment,
  useEditProfileMutation,
  UserDetailsFragment,
  useRemoveCardFromUserMutation,
  useUserBillingDetailsQuery,
} from "../generated/graphql"
import CormieSectionTitle from "../layouts/CormieSectionTitle"
import CardTokeniser from "./CardTokeniser"
import CormiePillBadge from "./CormiePillBadge"
import GenericBoolModal from "./GenericBoolModal"
import Loading from "./Loading"
import MainButton from "./MainButton"

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK || "")

type BillingTypes = {
  INVOICE: string
  CREDIT_CARD: string
}

const UserBillingInformation: React.FC<{
  user: UserDetailsFragment | undefined | null
  isEdit?: boolean
  userProfileMutationState?: EditProfileMutationVariables & {
    routeTags: (string | null)[]
  }
  setUserProfileMutationState?: React.Dispatch<
    React.SetStateAction<
      EditProfileMutationVariables & { routeTags: (string | null)[] }
    >
  >
  noPaymentCardError?: boolean
  setNoPaymentCardError?: React.Dispatch<React.SetStateAction<boolean>>
}> = ({
  user,
  isEdit,
  userProfileMutationState,
  setUserProfileMutationState,
  noPaymentCardError,
  setNoPaymentCardError,
}) => {
  const billingTypeMapping: BillingTypes = {
    INVOICE: "Invoice",
    CREDIT_CARD: "Credit card",
  }

  const { data, loading } = useUserBillingDetailsQuery({
    variables: { userId: user?.id || "" },
  })

  const [billingInputState, setBillingInputState] = useState({
    preferredPaymentMethod: user?.preferredPaymentMethod,
  })
  const [removeCardLoading, setRemoveCardLoading] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [showGenericBoolModal, setShowGenericBoolModal] = useState<boolean>()

  const [removeCardFromUser] = useRemoveCardFromUserMutation()
  const [updateUser] = useEditProfileMutation()

  const determinePrimary = (): PaymentSourceDetailsFragment | undefined => {
    let billingCard = undefined
    data?.extendedUser?.customer?.paymentSources?.forEach((card, i) => {
      // We pull the first card by default. Other cards will be ignored.
      if (i === 0 && card) {
        billingCard = card
      }
    })
    return billingCard
  }

  const [primaryPaymentSource, setPrimaryPaymentSource] = useState(
    determinePrimary(),
  )

  useEffect(() => {
    if (data) {
      setPrimaryPaymentSource(determinePrimary())
      if (determinePrimary() && setNoPaymentCardError) {
        setNoPaymentCardError(false)
      }
    }
  }, [data])

  useEffect(() => {
    if (
      billingInputState.preferredPaymentMethod !=
      userProfileMutationState?.preferredPaymentMethod
    ) {
      if (userProfileMutationState && setUserProfileMutationState) {
        setUserProfileMutationState({
          ...userProfileMutationState,
          preferredPaymentMethod: billingInputState.preferredPaymentMethod,
        })
      }
    }
  }, [userProfileMutationState])

  const handleBillingPrefChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setBillingInputState({
      ...billingInputState,
      preferredPaymentMethod: e.target.value,
    })
    if (userProfileMutationState && setUserProfileMutationState) {
      setUserProfileMutationState({
        ...userProfileMutationState,
        preferredPaymentMethod: e.target.value,
      })
    }
  }

  const handleOverrideEmailChange = (e: React.ChangeEvent<any>) => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (e.target.value != "") {
      if (!regex.test(e.target.value.toLowerCase())) {
        setEmailError(true)
      } else {
        setEmailError(false)
      }
    } else {
      if (emailError) setEmailError(false)
    }
    if (userProfileMutationState && setUserProfileMutationState) {
      setUserProfileMutationState({
        ...userProfileMutationState,
        invoiceEmailOverride: e.target.value,
      })
    }
  }

  return (
    <>
      {(user?.invoiceEmailOverride || isEdit) && (
        <>
          <Row className="mapped-profile-field">
            <Col className="muted-text d-flex align-items-center text-left col-12 col-sm-4 opacity-100">
              <strong>{"Company email override"}</strong>
            </Col>
            <Col className="text-overflow-wrap text-left">
              {isEdit ? (
                <>
                  <Form.Control
                    defaultValue={
                      userProfileMutationState?.invoiceEmailOverride ||
                      user?.invoiceEmailOverride ||
                      ""
                    }
                    className={emailError ? "is-invalid" : ""}
                    placeholder={"Email Address"}
                    name="invoiceEmailOverride"
                    id="invoiceEmailOverride"
                    onChange={handleOverrideEmailChange}
                    onBlur={handleOverrideEmailChange}
                  />
                  {emailError && (
                    <div style={{ color: "red" }}>
                      {"Please use valid email format, ex: name@website.com"}
                    </div>
                  )}
                </>
              ) : (
                <>{user?.invoiceEmailOverride}</>
              )}
            </Col>
          </Row>
          <hr></hr>
        </>
      )}
      <Row className="mb-2">
        <Col className="col-8 col-sm-auto m-0 me-sm-2 d-flex align-items-center">
          <h5 className="d-block d-sm-none m-0 mfs">
            {"Pref. payment method:"}
          </h5>
          <h5 className="d-none d-sm-block m-0 mfs">
            {"Preferred payment method:"}
          </h5>
        </Col>
        <Col className="col-4 col-sm-auto d-flex justify-content-end align-items-center">
          {isEdit ? (
            <>
              <span>
                <Form.Select
                  name="preferredPaymentMethod"
                  id="preferredPaymentMethod"
                  value={billingInputState.preferredPaymentMethod || ""}
                  onChange={handleBillingPrefChange}
                >
                  <option value={"CREDIT_CARD"}>{"Credit card"}</option>
                  <option value={"INVOICE"}>{"Invoice"}</option>
                </Form.Select>
              </span>
            </>
          ) : (
            <>
              <CormiePillBadge pink className="ms-3 p-2">
                <h6 className="my-0 mx-2">
                  {user?.preferredPaymentMethod
                    ? billingTypeMapping[
                        user.preferredPaymentMethod as keyof BillingTypes
                    ]
                    : "Credit card"}
                </h6>
              </CormiePillBadge>
            </>
          )}
        </Col>
      </Row>
      <hr></hr>
      {(isEdit
        ? billingInputState.preferredPaymentMethod
        : user?.preferredPaymentMethod) === "INVOICE" ? (
          <>
            <Row>
              <CormieSectionTitle horizontalRule={false}>
                <b>{"Invoices will be sent to:"}</b>
              </CormieSectionTitle>
            </Row>
            {user?.organisation[0].__typename === "organisation_Category" && (
              <>
                <Row className="mapped-profile-field">
                  <Col className="muted-text text-left col-12 col-sm-4">
                    {"Company name"}
                  </Col>
                  <Col className="text-overflow-wrap text-left">
                    {user.organisation[0].title}
                  </Col>
                </Row>
                <Row className="mapped-profile-field">
                  <Col className="muted-text text-left col-12 col-sm-4">
                    {"Company email"}
                  </Col>
                  <Col
                    className={
                      (user?.invoiceEmailOverride && !isEdit && "muted-text") +
                    " text-overflow-wrap text-left"
                    }
                  >
                    {user.organisation[0].organisationEmail}
                  </Col>
                </Row>
              </>
            )}
          </>
        ) : (
          <>
            {loading ? (
              <Loading />
            ) : (
              <>
                {primaryPaymentSource ? (
                  <>
                    <Row>
                      <Col>
                        <CormieSectionTitle
                          horizontalRule={false}
                          className={"mb-4"}
                        >
                          <b>{"Credit card details"}</b>
                        </CormieSectionTitle>
                      </Col>
                      {isEdit && (
                        <>
                          <GenericBoolModal
                            showGenericBoolModal={showGenericBoolModal}
                            onHide={() => undefined}
                          >
                            <div className="mt-3 mx-3">
                              <h5 className="mb-3">
                                <b>
                                  {"Are you sure you want to remove this card?"}
                                </b>
                              </h5>
                              <p>
                                {
                                  "This will change your billing preference back to 'invoice'"
                                }
                              </p>
                              <Row xs={1} sm={2} className="mt-4">
                                <Col className="w-auto">
                                  <MainButton
                                    className="m-0"
                                    onClick={async () => {
                                      if (
                                        primaryPaymentSource.id &&
                                      !removeCardLoading
                                      ) {
                                        setRemoveCardLoading(true)
                                        setShowGenericBoolModal(false)
                                        const result = await removeCardFromUser({
                                          variables: {
                                            id: primaryPaymentSource.id,
                                          },
                                          refetchQueries: ["userBillingDetails"],
                                        })
                                        if (
                                          result.data?.deletePaymentSource &&
                                        !result.errors &&
                                        userProfileMutationState
                                        ) {
                                          const result = await updateUser({
                                            variables: {
                                              ...userProfileMutationState,
                                              preferredPaymentMethod: "INVOICE",
                                            },
                                          })
                                          if (
                                            result.data?.updateExtendedUser?.id &&
                                          !result.errors
                                          ) {
                                            setBillingInputState({
                                              ...billingInputState,
                                              preferredPaymentMethod: "INVOICE",
                                            })
                                          }
                                        }
                                      }
                                      setRemoveCardLoading(false)
                                    }}
                                  >
                                    <b className="px-2">{"REMOVE CARD"}</b>
                                  </MainButton>
                                </Col>
                                <Col className="d-flex align-items-center ps-0 ps-sm-5 pt-4 pt-sm-0">
                                  <a
                                    onClick={() => {
                                      setShowGenericBoolModal(false)
                                    }}
                                  >
                                    <b>
                                      <u>{"CANCEL"}</u>
                                    </b>
                                  </a>
                                </Col>
                              </Row>
                            </div>
                          </GenericBoolModal>

                          <Col className="text-end">
                            <span className="text-muted text-end mx-2">
                              <a
                                onClick={() => {
                                  if (!removeCardLoading) {
                                    setShowGenericBoolModal(true)
                                  }
                                }}
                              >
                                {removeCardLoading && (
                                  <Spinner
                                    size="sm"
                                    animation="border"
                                    role="status"
                                    className="me-2"
                                  >
                                    <span className="visually-hidden">
                                    Loading...
                                    </span>
                                  </Spinner>
                                )}
                                {"Remove card"}
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  className="ms-1"
                                />
                              </a>
                            </span>
                          </Col>
                        </>
                      )}
                    </Row>
                    <Row className="mapped-profile-field mb-4" xs={1} sm={2}>
                      <Col className="muted-text text-left col-sm-auto">
                        {"Card number"}
                      </Col>
                      <Col className="text-overflow-wrap ps-0 ps-sm-5 text-left d-flex align-items-center">
                        <span className="credit-card-bullets me-1">
                          {
                            "\u2B24\u2B24\u2B24\u2B24   \u2B24\u2B24\u2B24\u2B24   \u2B24\u2B24\u2B24\u2B24"
                          }
                        </span>
                        {primaryPaymentSource.card?.last4}
                      </Col>
                    </Row>
                    <Row className="mapped-profile-field mb-4" xs={1} sm={2}>
                      <Col className="mb-2 mb-sm-0 w-auto">
                        <Row>
                          <Col className="muted-text text-nowrap text-left col-sm-auto">
                            {"Expiration date"}
                          </Col>
                          <Col
                            className="text-overflow-wrap text-left"
                            style={{
                              paddingLeft: "2rem",
                            }}
                          >
                            {primaryPaymentSource.card?.exp_month} /{" "}
                            {primaryPaymentSource.card?.exp_year}
                          </Col>
                        </Row>
                      </Col>
                      <Col className="ms-0 ms-sm-5 w-auto">
                        <Row>
                          <Col className="muted-text text-left">{"CVC"}</Col>
                          <Col className="text-overflow-nowrap ps-4 text-left d-flex align-items-center">
                            <span className="credit-card-bullets">
                              {"\u2B24\u2B24\u2B24" +
                              (primaryPaymentSource.card?.brand?.toLowerCase() ===
                              "american express"
                                ? "\u2B24"
                                : "")}
                            </span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    {isEdit ? (
                      <>
                        <h6>
                          <b>{"Add new payment card"}</b>
                        </h6>
                        {noPaymentCardError && (
                          <div style={{ color: "red" }}>
                            {"At least one card must be on file"}
                          </div>
                        )}
                        <Elements stripe={stripePromise}>
                          <CardTokeniser userId={user?.id || ""} />
                        </Elements>
                      </>
                    ) : (
                      <>
                        <span>{"No primary payment source on file"}</span>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
    </>
  )
}

export default UserBillingInformation
