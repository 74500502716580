import cartVar from "../../apollo/vars/cartVar"
import writeCartToLocalStorage from "./writeCartToLocalStorage"

const assignUserToCart = (uid: string) => {
  const existingCart = cartVar()
  existingCart.uid = uid
  cartVar(existingCart)
  writeCartToLocalStorage(existingCart)
}

export default assignUserToCart
