import React from "react"
import { Badge } from "react-bootstrap"

const CormiePillBadge: React.FC<{
  children: React.ReactNode
  pink?: boolean
  blue?: boolean
  className?: string
  onClick?: () => void
}> = ({ children, pink, blue, className, onClick }) => {
  return (
    <Badge
      pill
      onClick={onClick}
      className={
        (pink ? "pink " : "") +
        (blue ? "blue " : "") +
        (className ? className + " " : "") +
        "cormie-badge w-auto"
      }
    >
      {children}
    </Badge>
  )
}

export default CormiePillBadge
