import React from "react"
import { Row } from "react-bootstrap"

const CormieSection: React.FC<{
  left?: boolean
  right?: boolean
  className?: string
  children: React.ReactNode
}> = ({ children, left = false, right = false, className }) => {
  return (
    <Row
      className={
        (left ? "left " : "") +
        (right ? "right " : "") +
        (className ? className + " " : "") +
        "cormie-section mb-3"
      }
    >
      {children}
    </Row>
  )
}

export default CormieSection
