import { UserDetailsFragment } from "../../generated/graphql"

const getLocalPermacart = (
  permacartLineItems:
    | UserDetailsFragment["permacartLineItems"]
    | null
    | undefined,
) => {
  if (permacartLineItems && permacartLineItems.length > 0) {
    const lineItemsArray: { id: string; qty: number }[] = []
    permacartLineItems.forEach((lineItem) => {
      if (lineItem?.purchasable?.id && lineItem?.qty) {
        lineItemsArray.push({
          id: lineItem.purchasable.id,
          qty: lineItem.qty,
        })
      }
    })
    if (lineItemsArray.length > 0) {
      return lineItemsArray
    } else {
      return []
    }
  }
  return []
}

export default getLocalPermacart
