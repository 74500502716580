import { AllOrgCarersQuery, AllOrgClientsQuery } from "../../generated/graphql"
import searchObjectRecursively from "../search/searchObjectRecursively"

// Filter users using local state. This function handles a few cases
// and tries to reconcile the relationship between which tags are selected,
// whether a search term is entered, and whether we're ignoring tags
const filterUsers = (
  ignoreTags: boolean,
  clientData: AllOrgCarersQuery | AllOrgClientsQuery | undefined,
  localTags: Record<string, boolean>,
  searchTerm: string,
  ignoreOrg: boolean,
  userOrgId: string,
) => {
  const filteredUsersByTag: any[] = []
  const filteredUsersByOrg: any[] = []
  let filteredUsersByTerm: any[] = []
  let finalUsers: any[] = []

  if (ignoreOrg) {
    clientData?.extendedUsers.forEach((user) => {
      if (user) filteredUsersByOrg.push(user)
    })
  } else {
    clientData?.extendedUsers.forEach((user) => {
      let clientOrgId = ""
      const clientOrg = user?.organisation[0]
      if (clientOrg?.__typename === "organisation_Category") {
        clientOrgId = clientOrg.id || ""
      }
      if (clientOrgId === userOrgId && user) {
        filteredUsersByOrg.push(user)
      }
    })
  }

  if (ignoreTags) {
    filteredUsersByOrg.forEach((user) => {
      if (user && user.id) filteredUsersByTag.push(user)
    })
  } else {
    Object.keys(localTags).forEach((tag) => {
      filteredUsersByOrg.forEach((user) => {
        if (
          localTags[tag] &&
          user?.id &&
          user?.routeTags.includes(tag) &&
          !Object.keys(filteredUsersByTag).includes(user.id)
        ) {
          filteredUsersByTag.push(user)
        }
      })
    })
  }

  if (searchTerm != "") {
    filteredUsersByTerm = filteredUsersByTag.filter((user) =>
      searchObjectRecursively(user, searchTerm),
    )
    finalUsers = filteredUsersByTerm
  } else {
    finalUsers = filteredUsersByTag
  }
  return finalUsers
}

export default filterUsers
