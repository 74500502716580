import React from "react"
import {
  AllOrgOrderDetailsFragment,
  useCancelOrderMutation,
} from "../generated/graphql"
import CormiePillBadge from "./CormiePillBadge"
import Loading from "./Loading"

const CancelOrderPillBadge: React.FC<{
  order: AllOrgOrderDetailsFragment | null
}> = ({ order }) => {
  const [cancelOrder, { loading: cancelLoading, error: cancelError }] =
    useCancelOrderMutation()

  return (
    <>
      {order && order.id && (
        <CormiePillBadge
          className={!!cancelError || cancelLoading ? "" : "hover-order"}
          pink
          onClick={() => {
            if (order.id && !cancelError) {
              cancelOrder({
                variables: {
                  id: order.id,
                  refund: true,
                },
              })
            }
          }}
        >
          <div className="opacity-75">
            {cancelLoading ? (
              <Loading small />
            ) : cancelError ? (
              "Error"
            ) : (
              "Cancel"
            )}
          </div>
        </CormiePillBadge>
      )}
    </>
  )
}

export default CancelOrderPillBadge
