import cartVar from "../../apollo/vars/cartVar"
import assignUserToCart from "./assignUserToCart"
import resetCart from "./resetCart"

const setCart = (uid: string) => {
  const existingCart = cartVar()
  if (!existingCart.uid || existingCart.uid !== uid) {
    resetCart()
    assignUserToCart(uid)
  }
}

export default setCart
