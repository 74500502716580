import React, { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"

const PaginationControls: React.FC<{
  items: any[] | undefined
  setPaginatedItems: React.Dispatch<React.SetStateAction<any[] | undefined>>
}> = ({ items, setPaginatedItems }) => {
  const paginationOptions = [25, 50, 75, 100]

  const [resultNumber, setResultNumber] = useState<number>(25)
  const [pageNumber, setPageNumber] = useState<{
    currentPage: number
    maxPage: number
  }>({ currentPage: 1, maxPage: 1 })

  useEffect(() => {
    if (items) {
      setPaginatedItems(paginateItems(items))
      determinePages(items)
    }
  }, [resultNumber, pageNumber, items])

  const paginateItems = (items: any[]) => {
    return items.filter((_, i) => {
      if (
        i + 1 <= resultNumber * pageNumber.currentPage &&
        i + 1 > resultNumber * (pageNumber.currentPage - 1)
      ) {
        return true
      }
      return false
    })
  }

  const determinePages = (items: any[]) => {
    if (items.length > 0) {
      const newMaxPage = Math.ceil(items.length / resultNumber)
      if (
        newMaxPage !== pageNumber.maxPage &&
        newMaxPage > pageNumber.currentPage
      ) {
        setPageNumber({
          ...pageNumber,
          maxPage: Math.ceil(items.length / resultNumber),
        })
      } else if (
        newMaxPage !== pageNumber.maxPage &&
        newMaxPage < pageNumber.currentPage
      ) {
        setPageNumber({
          currentPage: 1,
          maxPage: Math.ceil(items.length / resultNumber),
        })
      }
    }
  }

  const handleResultNumberChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setResultNumber(Number(event.target.value))
    setPageNumber({
      currentPage: 1,
      maxPage: 1,
    })
  }

  return (
    <Row className="mt-1 mb-3 px-3 row flex-column-reverse flex-md-row">
      <div className="result-num-wrapper mx-auto mt-2 mt-md-0">
        <div className="result-num-div">
          <label className="me-2 light-text muted-text">Show</label>
          <select
            className="result-number-input"
            onChange={handleResultNumberChange}
            value={resultNumber}
          >
            {paginationOptions.map((option, i) => (
              <option key={i} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>
      <Col className="d-flex justify-content-center align-items-center">
        <div
          className={
            (pageNumber.currentPage === 1 ? "disabled " : "") +
            "pagination-select previous"
          }
          onClick={() => {
            if (pageNumber.currentPage > 1) {
              setPageNumber({
                ...pageNumber,
                currentPage: pageNumber.currentPage - 1,
              })
            }
          }}
        >
          <a className="border-right pe-3">{"PREVIOUS"}</a>
        </div>
        <div className="mx-3">
          <span className="d-none d-sm-inline">{"Page"}</span>{" "}
          {pageNumber.currentPage} of {pageNumber.maxPage}
        </div>
        <div
          className={
            (pageNumber.currentPage === pageNumber.maxPage ? "disabled " : "") +
            "pagination-select next"
          }
          onClick={() => {
            if (pageNumber.currentPage < pageNumber.maxPage) {
              setPageNumber({
                ...pageNumber,
                currentPage: pageNumber.currentPage + 1,
              })
            }
          }}
        >
          <a className="border-left ps-3">{"NEXT"}</a>
        </div>
      </Col>
    </Row>
  )
}

export default PaginationControls
