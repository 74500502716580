const getGender = (
  gender: string | null | undefined,
): "male" | "female" | undefined => {
  if (gender) {
    const downcasedGender = gender.toLowerCase()
    if (downcasedGender === "male" || downcasedGender === "female") {
      return downcasedGender
    } else {
      return undefined
    }
  }
  return undefined
}

export default getGender
