import { CountryVarType } from "../../apollo/vars/countryVar"
import { LocalUserQuery } from "../../generated/graphql"

const processCountryData = (data: LocalUserQuery): CountryVarType => {
  const returnObj: CountryVarType = {}

  data.countries?.forEach((country) => {
    if (country?.id && country.name && country.states) {
      const countryId = country.id
      returnObj[countryId] = {
        name: country.name,
        id: country.id,
        states: {},
      }

      country.states.forEach((state) => {
        if (state?.id && state?.name && state?.abbreviation) {
          returnObj[countryId].states[state.id] = {
            name: state.name,
            id: state.id,
            abbreviation: state.abbreviation
          }
        }
      })
    }
  })

  return returnObj
}

export default processCountryData
