import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Row } from "react-bootstrap"

const SearchBar: React.FC<{
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
}> = ({ onChange, placeholder }) => {
  return (
    <Row className="search-bar-row my-2 justify-content-center">
      <div className="input-group my-4">
        <div className="search-input-wrapper">
          <input
            className="search-bar-input px-5 py-3 form-control"
            type="text"
            onChange={onChange}
            name="name"
            placeholder={placeholder}
          />
        </div>
        <div className="input-group-prepend">
          <FontAwesomeIcon icon={faSearch} className={"search-icon"} />
        </div>
      </div>
    </Row>
  )
}

export default SearchBar
