import React from "react"
import { Col, Row } from "react-bootstrap"
import { ClientOrderQuery } from "../generated/graphql"

const OrganisationInformation: React.FC<{
  order: ClientOrderQuery["order"] | undefined
}> = ({ order }) => {
  return (
    <>
      {order?.poNumber && (
        <Row className="mapped-profile-field">
          <Col className="muted-text text-left col-12 col-sm-4">
            {"PO number"}
          </Col>
          <Col className="text-overflow-wrap text-left">
            {`#${order?.poNumber}`}
          </Col>
        </Row>
      )}
      {order?.customer?.user?.organisation[0].__typename ===
        "organisation_Category" && (
        <>
          <Row className="mapped-profile-field">
            <Col className="muted-text text-left col-12 col-sm-4">{"Name"}</Col>
            <Col className="text-overflow-wrap text-left">
              {`${order?.customer?.user?.organisation[0].title}`}
            </Col>
          </Row>
          <Row className="mapped-profile-field">
            <Col className="muted-text text-left col-12 col-sm-4">
              {"Address"}
            </Col>
            <Col className="text-overflow-wrap text-left">
              {`${order.customer.user.organisation[0].billingAddress}`}
            </Col>
          </Row>
          <Row className="mapped-profile-field">
            <Col className="muted-text text-left col-12 col-sm-4">{"ABN"}</Col>
            <Col className="text-overflow-wrap text-left">
              {`${order.customer.user.organisation[0].abnNumber}`}
            </Col>
          </Row>
          <Row className="mapped-profile-field">
            <Col className="muted-text text-left col-12 col-sm-4">
              {"AR no."}
            </Col>
            <Col className="text-overflow-wrap text-left">
              {`${order.customer.user.organisation[0].arNumber}`}
            </Col>
          </Row>
        </>
      )}
    </>
  )
}

export default OrganisationInformation
