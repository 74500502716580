import React from "react"
import { Col, Row } from "react-bootstrap"
import {
  ClientOrderQuery,
  useAllOrgCarersQuery,
  useLocalUserQuery,
} from "../generated/graphql"
import { useNavigate } from "react-router-dom"
import Loading from "./Loading"

const OrderInformation: React.FC<{
  order: ClientOrderQuery["order"] | undefined
}> = ({ order }) => {
  return (
    <>
      {order?.poNumber && (
        <Row className="mapped-profile-field">
          <Col className="muted-text text-left col-12 col-sm-4">
            {"PO number"}
          </Col>
          <Col className="text-overflow-wrap text-left">
            {`#${order.poNumber}`}
          </Col>
        </Row>
      )}

      <Row className="mapped-profile-field">
        <Col className="muted-text text-left col-12 col-sm-4">
          {"Is recurring order?"}
        </Col>
        <Col className="text-overflow-wrap text-left">
          {`${Number(order?.isDuplicatedStandingOrder) ? "Yes" : "No"}`}
        </Col>
      </Row>

      {order?.orderCarerId && (
        <CarerDetails
          orderCarerId={order.orderCarerId}
          isDuplicatedStandingOrder={order?.isDuplicatedStandingOrder}
        />
      )}
    </>
  )
}

const CarerDetails: React.FC<{
  orderCarerId: number
  isDuplicatedStandingOrder: string | null | undefined
}> = ({ orderCarerId, isDuplicatedStandingOrder }) => {
  const navigate = useNavigate()

  const { data, loading } = useLocalUserQuery() // should be cached from login
  const { data: carersData, loading: carersLoading } = useAllOrgCarersQuery()

  let carerData:
    | {
        id: string | null | undefined
        fullName: string | null | undefined
        isCurrentUser: boolean
      }
    | undefined

  if (data?.me?.id && orderCarerId === Number(data.me.id)) {
    carerData = {
      id: data.me.id,
      fullName: data.me.fullName,
      isCurrentUser: true,
    }
  } else {
    const orderCarer = carersData?.extendedUsers.find(
      (e) => Number(e?.id) === orderCarerId,
    )
    if (orderCarer) {
      carerData = {
        id: orderCarer.id,
        fullName: orderCarer.fullName,
        isCurrentUser: false,
      }
    }
  }

  return loading || carersLoading ? (
    <Loading />
  ) : (
    <>
      <Row className="mapped-profile-field">
        <Col className="muted-text text-left col-12 col-sm-4">
          {Number(isDuplicatedStandingOrder)
            ? "Order last modified by"
            : "Order placed by"}
        </Col>
        <Col className="text-overflow-wrap text-left">
          {carerData?.fullName ? (
            <>
              <a
                onClick={() => {
                  const navUrl = carerData?.isCurrentUser
                    ? "/profile"
                    : `/employees/${carerData?.id}`
                  navigate(navUrl)
                }}
              >
                <u>{carerData?.fullName || "Admin"}</u>
              </a>
            </>
          ) : (
            "Admin"
          )}
        </Col>
      </Row>
    </>
  )
}

export default OrderInformation
