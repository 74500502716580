import React, { useState } from "react"
import { Breadcrumb } from "react-bootstrap"
import { useLocation, useNavigate } from "react-router-dom"
import { IconDefinition } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  BreadcrumbUserDocument,
  BreadcrumbUserQuery,
} from "../generated/graphql"
import { useQuery } from "@apollo/client"
import { faCartShopping, faHouse } from "@fortawesome/pro-regular-svg-icons"
import { faUser } from "@fortawesome/free-regular-svg-icons"

// The below path map illustrates which paths will be recognised by the
// breadcrumb component. If the path is not in the path map, it will not be
// rendered as part of the breadcrumb
export const pathMap: Record<
  string,
  {
    name: string
    icon?: IconDefinition
    userPath?: boolean
    orderPath?: boolean
  }
> = {
  customers: {
    name: "CUSTOMER CENTRE",
    icon: faHouse,
    userPath: true,
  },
  "create-customer": {
    name: "New client",
  },
  "create-employee": {
    name: "New employee",
  },
  "edit-customer": {
    name: "EDIT CUSTOMER",
  },
  orders: {
    name: "ORDER HISTORY",
    icon: faHouse,
    orderPath: true,
  },
  "new-order": {
    name: "NEW ORDER",
  },
  employees: {
    name: "EMPLOYEES",
    icon: faHouse,
    userPath: true,
  },
  "edit-employee": {
    name: "EDIT EMPLOYEE",
  },
  profile: {
    name: "YOUR PROFILE",
    icon: faUser,
  },
  "edit-profile": {
    name: "EDIT PROFILE",
  },
  cart: {
    name: "CART",
    icon: faCartShopping,
  },
}

const HeaderBreadcrumb: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [breadcrumbUser, setBreadcrumbUser] = useState<{
    uid?: number
    name?: string
  }>({})

  // First we split the current url into its individual parts, split by each slash
  const pathArray = location.pathname.split("/").filter((e) => e)

  // We initialiase and assign a string to be our "built path" which is used to
  // create accurate breadcrumb links, even if we don't render every part of the
  // url itself in the breadcrumb
  let builtPath = ""

  // We check at the top level to see if there is a user path being used,
  // therefore requiring us to rerender the component with the username
  if (!breadcrumbUser.uid) {
    const userPathKeys = Object.keys(pathMap).filter(
      (key) => pathMap[key].userPath,
    )
    userPathKeys.forEach((key) => {
      const index = pathArray.findIndex((element) => element === key)
      if (index >= 0 && !!Number(pathArray[index + 1])) {
        setBreadcrumbUser({
          uid: Number(pathArray[index + 1]),
        })
      }
    })
  }

  useQuery<BreadcrumbUserQuery>(BreadcrumbUserDocument, {
    variables: {
      id: breadcrumbUser.uid,
    },
    skip: !breadcrumbUser.uid,
    onCompleted: (data) => {
      if (data?.extendedUser?.fullName) {
        setBreadcrumbUser({
          ...breadcrumbUser,
          name: data.extendedUser.fullName,
        })
      }
    },
  })

  // To construct the breadcrumb header, we map over the path array we made before
  const breadcrumbs = pathArray.map((path, i) => {
    builtPath += "/" + path
    // We check to see if there is a matching path in our path map
    if (pathMap[path]) {
      // We extract the object from our pathmap for easier reference
      const pathData = pathMap[path]

      // Deep copy the path so we're not referencing the updated path later
      const crumbPath = (" " + builtPath).slice(1)

      // We return *one* breadcrumb item to the mapped array
      return (
        <React.Fragment key={i}>
          <Breadcrumb.Item
            onClick={() => {
              navigate(crumbPath)
            }}
          >
            <>
              {pathData.icon && (
                <>
                  <FontAwesomeIcon
                    icon={pathData.icon}
                    title={"breadcrumb icon"}
                    className={"me-2"}
                  />
                </>
              )}
              {pathData.name}
            </>
          </Breadcrumb.Item>
          {/* We check to see if we're at an "order" or "user" path, ie:
          site.com/users => user path
          site.com/orders => order path
          If so, we check to see if there is another part of the url next.
          If so, we can assume this is either the user or order ID, and we can pull 
          the data accordingly */}
          {(pathData.userPath || pathData.orderPath) &&
            !!Number(pathArray[i + 1]) && (
            <Breadcrumb.Item
              onClick={() => {
                if (pathData.userPath) {
                  if (crumbPath.split("/").includes("employees")) {
                    navigate(`employees/${breadcrumbUser.uid}`)
                  } else if (crumbPath.split("/").includes("customers")) {
                    navigate(`customers/${breadcrumbUser.uid}`)
                  }
                }
              }}
            >
              {pathData.userPath && <>{breadcrumbUser?.name}</>}
              {pathData.orderPath && <>{`ORDER #${pathArray[i + 1]}`}</>}
            </Breadcrumb.Item>
          )}
        </React.Fragment>
      )
    } else {
      return null
    }
  })

  return (
    <Breadcrumb className="my-0">
      {pathArray.length === 0 && (
        <Breadcrumb.Item active>
          <FontAwesomeIcon icon={faHouse} />
          {" HOME"}
        </Breadcrumb.Item>
      )}
      {breadcrumbs}
    </Breadcrumb>
  )
}

export default HeaderBreadcrumb
