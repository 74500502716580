const parseInterval = (days: number | undefined | null): string => {
  if (days) {
    // We can add support for months if we want, but this will be more vague
    if (days % 28 === 0) {
      // Then it divides cleanly into months
      return `${days / 28} ${days / 28 > 1 ? "months" : "month"}`
    } else if (days % 7 === 0) {
      // then it divides cleanly into weeks
      return `${days / 7} ${days / 7 > 1 ? "weeks" : "week"}`
    } else {
      return `${days} ${days > 1 ? "days" : "day"}`
    }
  } else {
    return "Stopped"
  }
}

export default parseInterval
