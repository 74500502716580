import React from "react"
import { Container } from "react-bootstrap"

const CormiePageContainer: React.FC<{
  className?: string
  children: React.ReactNode
}> = ({ className, children }) => {
  return (
    <Container className={(className ? className + " " : "") + "p-0 p-sm-auto"}>
      {children}
    </Container>
  )
}

export default CormiePageContainer
