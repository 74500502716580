import { makeVar } from "@apollo/client"

export type CountryVarType = {
  [key: string]: {
    name: string
    id: string
    states: {
      [key: string]: {
        name: string
        id: string
        abbreviation: string
      }
    }
  }
}
const initialState: CountryVarType = {}

const countryVar = makeVar(initialState)

export default countryVar
