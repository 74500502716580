import cartVar from "../../apollo/vars/cartVar"
import writeCartToLocalStorage from "./writeCartToLocalStorage"

const addToCart = (id: string, quantity: number) => {
  const currentCart = cartVar()

  if (Object.keys(currentCart.cart).includes(id)) {
    currentCart.cart[id] = currentCart.cart[id] + quantity
  } else {
    currentCart.cart[id] = quantity
  }

  cartVar({ ...currentCart })
  writeCartToLocalStorage(currentCart)
}

export default addToCart
