import { makeVar } from "@apollo/client"
import { CategoryDetailsFragment } from "../../generated/graphql"

export type ProductCategoryVarType = {
  female: CategoryDetailsFragment[]
  male: CategoryDetailsFragment[]
}

const initialState: ProductCategoryVarType = {
  female: [],
  male: [],
}

const productCategoryVar = makeVar(initialState)

export default productCategoryVar
