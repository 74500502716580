import React from "react"

const PageTitle: React.FC<{
  children: React.ReactNode
  className?: string
}> = ({ children, className }) => {
  return (
    <h1 className={(className ? className + " " : "") + "mt-5"}>{children}</h1>
  )
}

export default PageTitle
