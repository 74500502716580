import { faEdit } from "@fortawesome/free-regular-svg-icons"
import { faSquareFull } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Col } from "react-bootstrap"

const CormieProfileEditButton: React.FC<{
  editText?: string
  editCallback?: () => void
  className?: string
}> = ({ editText, editCallback, className }) => {
  return (
    <Col
      className={
        "col-12 col-sm-6 d-flex justify-content-end align-items-center " +
        className
      }
    >
      <p className="edit-text hoverable m-0" onClick={editCallback}>
        <span className="fa-layers fa-fw" style={{ background: "white" }}>
          <FontAwesomeIcon
            icon={faEdit}
            mask={faSquareFull}
            className="red-blue-gradient-icon"
            inverse
          />
        </span>
        <u>{editText ? editText : "edit"}</u>
      </p>
    </Col>
  )
}

export default CormieProfileEditButton
