import React from "react"
import Modal from "react-bootstrap/Modal"

const GenericBoolModal: React.FC<{
  showGenericBoolModal?: boolean
  children: React.ReactNode
  onHide: () => void
}> = ({ showGenericBoolModal = false, children, onHide }) => {
  return (
    <Modal
      show={showGenericBoolModal}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  )
}

export default GenericBoolModal
