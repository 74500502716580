import { useReactiveVar } from "@apollo/client"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { faBasketShopping } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import cartVar from "../apollo/vars/cartVar"
import getCartCount from "../utils/cart/getCartCount"
import resetCart from "../utils/cart/resetCart"
import ClearCartContent from "./ClearCartContent"
import GenericBoolModal from "./GenericBoolModal"

const CartWidget: React.FC<{
  noCart?: boolean
  ignorePath?: boolean
}> = ({ noCart, ignorePath }) => {
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState<boolean>()
  const [modalCallback, setModalCallback] = useState<(() => void) | undefined>()

  const cart = cartVar()
  useReactiveVar(cartVar)

  const splitPath = location.pathname.split("/")

  return (
    <>
      <GenericBoolModal
        showGenericBoolModal={showModal}
        onHide={() => {
          setShowModal(false)
        }}
      >
        <ClearCartContent
          setShowModal={setShowModal}
          callback={modalCallback}
        />
      </GenericBoolModal>

      <span
        onClick={() => {
          if (cart.uid && Object.keys(cart.cart).length > 0) {
            setModalCallback(() => () => {
              navigate("/customers")
            })
            setShowModal(true)
          } else {
            resetCart()
            navigate("/customers")
          }
        }}
      >
        {(ignorePath || !splitPath.includes("customers")) && (
          <>
            <FontAwesomeIcon icon={faPlus} className="me-3" />
            <a>{"NEW ORDER"}</a>
            {!noCart && <span className={"ms-3 me-4 border-end"} />}
          </>
        )}
      </span>
      {!noCart && (
        <>
          <span>
            <span
              className={"basket-counter-span"}
              onClick={() => {
                navigate("/cart")
              }}
            >
              {getCartCount()}
            </span>
            <FontAwesomeIcon icon={faBasketShopping} className="basket-icon" />
          </span>
        </>
      )}
    </>
  )
}

export default CartWidget
